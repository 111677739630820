.cards-grid {
    display: flex;
    flex-wrap: wrap;


    & > * {
        position: relative;
        flex: 0 1 100%;
        padding: 1.5rem;
        box-sizing: border-box;

        @media (min-width: 768px) {
            flex: 0 1 50%;

            &:first-child {
                flex: 0 1 100%;
                border: none;
                &::before,
                &::after {
                    content: none;
                }
            }

            &:first-child .card-content {
                display: flex;
                flex-direction: row;
                gap: 3rem;
            }

            &:first-child .card-image-container {
                width: 60%;
            }


           

            &:first-child .card-body {
                width: 40%;
            }
            &:first-child .card-body.half-grid,
            &:first-child .card-image-container.half-grid  {
                width: 50%;
            }

            &:first-child .card-read-more {
                position: relative;
            }
        }

        @media (min-width: 1024px) {
            flex: 0 1 33.333%;
        }
    }
}


.general .card-image-container {
 margin-bottom: 2rem;
}