/* tally meter */

.tally-meters {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-content: flex-start;
    flex-direction: column;

    > li {
        display: flex;
        align-content: center;
    }

    &.column {
        flex-grow: 0.66;
        //max-width: max-content;
        min-width: 10rem;
        max-width: 29.1rem;
    }

    .tally-meter {
        font-size: 1.5rem;
        height: 4rem;
        width: 4rem;
        border: 0.066rem solid currentcolor;
        border-radius: 100%;
        line-height: 4rem;
        text-align: center;
        margin-inline-end: 1rem;
        flex-shrink: 0;
    }
    .tally-meter + span {
        align-self: center;
        font-size: 0.75rem;
    }
}

#main .tally-meters{
    margin-top: 0vw;
    padding-top:2rem;
}

#vermogensbeheer .tally-meters {
    margin-top: 7vw;
}
