main > section.strip.contact {
    display: flex;
    justify-content: space-between;
    gap: 8rem;
    flex-wrap: nowrap;
    padding-inline: max(10vw, 50vw - 43.75rem);
}

main > section.strip.contact > form {
    flex-basis: calc(75% - 2rem);
    padding-block-start: 2.1rem;
}

main section.strip .contact h2 {
    padding-block-end: 0rem !important;
}

main section.strip.contact p {
    padding-block-end: 2rem;
}


main > section.strip.visual__version {
    background-image: url('../../assets/images/home/header_background_lady.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    min-height: 40vh;

    h1 {
        margin-bottom: .4rem;
    }
    @media screen and (max-width: 1100px) {
        background-size: cover;
       
    }
    p {
        max-width:50%;
    }

    @media screen and (max-width: 680px) {
        p {
            max-width:70%;
        }
    }
}
@media screen and (max-width: 52rem) {
    main > section.strip.visual__version {
        
        background-image: url('../../assets/images/home/header_background_lady_mobile.jpg');
        background-size: contain;
        background-position: top right;

        p {
            max-width:100%;
        }

        h1 {
            margin-top:16vh;
            font-size: 2.5rem;
            margin-bottom: .4rem;
        }
    

    }
  
    main > section.strip.contact {
        display: block;
    }

    main > section.strip.contact > form {
        padding-block-start: 2rem;
    }
}
