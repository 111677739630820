.admin {
    font-family: 'Avenir', 'Avenir', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

    .sidebar {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 175px;
        background-color: var(--color-blue);
        color: #fff;
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        z-index: 80;

        .sidebar-header {
            font-size: 1.5rem;
            padding-left: 20px;
            margin-bottom: 1rem;
        }

        .sidebar-nav {
            width: 100%;
            display: flex;
            flex-direction: column;

            .nav-section {
                margin-bottom: 1.5rem;

                .nav-heading {
                    font-size: 1rem;
                    font-weight: bold;
                    padding: 10px 10px;
                    color: #ddd;
                    text-transform: uppercase;
                }

                a {
                    text-decoration: none;
                    font-size: 0.9rem;
                    padding: 10px 10px;
                    color: #fff;
                    display: block;
                    margin-inline: 10px;
                }

                a.current {
                    background-color: #fff;
                    color: var(--color-blue);
                    border-radius: 5px;
                }
            }
        }

        .logout-btn {
            position: absolute;
            bottom: 20px;
            left: 20px;
            color: #fff;
            text-decoration: none;
            font-size: 0.8rem;
        }
    }

    .main-container {
        margin-left: 175px;
        padding: 20px;
        max-width: calc(100% - 175px);
        padding-bottom: 60px;
        padding-top: 1rem;
    }

    .top-bar {
        display: flex;
        justify-content: flex-start;
        padding: 10px 20px;
        background-color: var(--color-blue);
        color: #fff;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 90;
    }

    .btn-link {
        text-decoration: none;
        color: var(--color-blue) !important;
    }

    .table-responsive {
        padding: 20px;
    }

    .btn-primary {
        background-color: var(--color-blue);
        border-color: var(--color-blue);
        color: #fff !important;
        padding: 10px 20px;
    }

    label {
        margin: 10px 0;
        display: block;
        position: relative;
    }

    h1 {
        min-height: auto;
    }

    form input[type='text'],
    form input[type='tel'],
    form input[type='email'] {
        line-height: 1.5em;
        border: var(--bs-border-width) solid var(--bs-border-color);
        border-radius: var(--bs-border-radius);
        height: auto;
    }

    .page-link,
    .active > .page-link,
    .page-link.active {
        color: var(--color-blue);
    }

    .active > .page-link,
    .page-link.active {
        background-color: var(--color-blue);
        border-color: var(--color-blue);
        color: white;
    }

    th > a {
        text-decoration: none;
        color: var(--color-blue);
    }

    .post-title-table,
    .post-title-table:visited {
        color: var(--color-blue);
        text-decoration: none;
    }

    .post-title-column {
        width: 50%;
        overflow: hidden;
    }

    .edit-button {
        font-size: 1em;
        width: 100px;
        padding: 5px 10px;
        border-radius: 6px;
    }

    .edit-button i {
        display: inline;
    }

    .login {
        margin-top: 4rem;
    }

    label {
        width: 100% !important;
    }

    .teammember {
        .tox-editor-header {
            display: none;
        }
        .tox-statusbar {
            display: none;
        }
    }
}
