/* SVG */
/*------------------------------------------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------------------------------------------*/
/*WMP Logo*/
.wcls-1 {
    fill: #202a52;
}
.wcls-2 {
    fill: #fff;
}
/*WMPS Logo*/
.wscls-1 {
    fill: #202a52;
}
.wscls-2 {
    fill: #fff;
}

.navicon {
    fill: #202a52;
}
.navigation-icon-list a:hover .navicon {
    fill: #e63214;
}
