.header-navigation {
    display: flex;
    padding-block: 0.625rem;
    height: 5rem;
    width: 100%;
    position: relative;
}

.header-navigation .logo-container {
    height: 5rem;
    width: 4rem;
}

.header-navigation .logo-container svg {
    width: 100%;
    height: 100%;
    display: block;
}

.header-navigation-list {
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    padding-inline-start: 2rem;
    position: relative;
}

.header-navigation-list > li {
    padding-inline: 1rem;
    position: relative;
    align-self: center;

}

.header-navigation-list a.current {
    font-weight: 900;
}

.header-navigation-list > .navigation-icons {
    padding-inline: 0;
    margin-inline-start: auto;
}

.header-navigation .navigation-icon-list {
    display: flex;
    gap: 0.5rem;
}

.header-navigation .navigation-icon-list svg {
    height: 1.2rem;
    display: inline-block;
}

.header-navigation .navigation-icon-list a {
    border: 0.08rem solid var(--color-blue);
    border-radius: 10rem;
    line-height: 1rem;
    padding: 0.25rem .5rem;
    font-weight: 300;
    font-size: 0.75rem;
    display: inline-block;
    vertical-align: middle;
}

.header-navigation .navigation-icon-list a:hover,
.header-navigation .navigation-icon-list a:focus,
.header-navigation .navigation-icon-list a:active {
    text-decoration: none;
    color: var(--color-white);
    background-color: var(--color-blue);
}

.header-navigation .sub-navigation-list {
    display: none;
    position: absolute;
    //width: 100vw;
    inset: 100% auto auto 0;
    // padding: calc(1.5rem + 0.625rem) 0 1.5rem 1rem;
    padding:1.6rem 1rem 1rem 1rem;
    min-width: 250px;
    background-image: linear-gradient(transparent 0.625rem, #dcdcdc 0.625rem);
    line-height: 2;
    border-top: 31px solid white;
    a {
        display: block;
    }
}

.header-navigation .sub-navigation-list::after {
    content: '';
    position: absolute;
    background-image: linear-gradient(transparent 0.625rem, #dcdcdc 0.625rem);
    width: 100vw;
    inset: 0 100vw 0 auto;
    display: block;
}

.header-navigation .sub-navigation-list-supplement {
    position: fixed;
    inset: var(--height-header) max(10vw, 50vw - 43.75rem) auto auto;
    padding-block-start: 1.5rem;
    font-size: 0.75rem;
    text-align: end;
    line-height: 1.5;
    display: none;
}

.header-navigation .sub-navigation-list li:not(:last-child),
.header-navigation .sub-navigation-list-supplement li:not(:last-child) {
    padding-block-end: 0.25em;
}

.header-navigation .sub-navigation-list.active,
.header-navigation-list > li:hover > .sub-navigation-list,
.header-navigation .sub-navigation-list:hover,
.header-navigation-list > li:focus-within .sub-navigation-list {
    display: block;
}

.header-navigation .navigation-toggle {
    display: none;
    position: absolute;
    inset: 1.9375rem 0 1.9375rem auto;
    width: 1.375rem;
    height: 1.375rem;
    background-image: linear-gradient(transparent 90%, var(--color-blue) 90%);
    padding-inline-start: 1.375rem;
    overflow: hidden;
}

.header-navigation .navigation-toggle::before,
.header-navigation .navigation-toggle::after {
    content: '';
    background-color: var(--color-blue);
    position: absolute;
    display: block;
    transition: transform 150ms linear;
}

.header-navigation .navigation-toggle::before {
    inset: 0 0 1.25rem 0;
}

.header-navigation .navigation-toggle::after {
    inset: 0.625rem 0 0.625rem 0;
}

.header-navigation.expanded .navigation-toggle::before {
    transform: translateY(0.4678rem) rotate(45deg);
}

.header-navigation.expanded .navigation-toggle::after {
    transform: translateY(-0.1572rem) rotate(-45deg);
}
