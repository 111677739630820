/* link behaviour */

a {
    text-decoration: none;
    color: inherit;
    text-underline-offset: 0.25em;
}

a:visited {
    color: inherit;
}

a:hover,
a:focus {
    text-decoration: underline;
}
.content-left p a {
    text-decoration: underline;
}
h1 {
    font-size: calc(1rem + 1.9vw);
    font-weight: 400;
    min-height: 12.5rem;
}
h2 {
    font-weight: 400;
    font-size: calc(1.5rem + 0.5vw);
    // margin-bottom:1rem;
    line-height: 1.2;
}
.disclaimer {
    font-size: 14px;
    padding-block: 1rem;
    font-style: italic;
}

// .bar {
//     padding-top: 32px;
// }

/* Bar is applied to heading elements to create the red bar. */
.bar::before {
    content: '';
    display: block;
    background-color: var(--color-red);
    height: 0.35rem;
    width: 4rem;
    margin-bottom: 0.75rem;
}
/* additional section styling for typo elements */
section > p:not(:last-child),
// section > h2:not(:last-child),
section > h3:not(:last-child),
section > ul:not(:last-child),
section > ol:not(:last-child),
section > figure:not(:last-child) {
    padding-block-end: 2rem;
}


.capitalize::first-letter{
    text-transform: capitalize;
}