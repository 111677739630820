.assistive,
label,
legend {
    // clip: rect(0 0 0 0);
    // clip-path: inset(50%);
    // height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
 
    
}

.inline-form {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
    
    label {
        overflow: initial;
        padding-left: 1rem;
        font-size: 0.85rem;
        position: relative;
        // top: -25px;
        left: 5.7px;
        white-space: unset;
        width: 100% !important;
        
    }
    input {
        flex:inherit;
        align-self: center;
    }
}
form {
    button[type='submit'] {
        margin-block-start: 2rem;
    }

    fieldset {
        appearance: none;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border: 0;
    }

    input {
        border: none;
        font-size: 0.75rem;
    }

    input[type='text'],
    input[type='tel'],
    input[type='email'] {
        border: 0.1em solid var(--color-blue);
        padding: 0.5em 1em;
        line-height: 1;
        height: 1em;
        border-radius: 1.1em;
    }

    select {
        appearance: none;
        border: 0.1em solid var(--color-blue);
        padding: 0.5em 1em;
        border-radius: 1.25em;
        color: var(--color-blue);
        font-size: 0.75rem;
        background-color: transparent;
        cursor: pointer;
        font-family: 'Avenir', arial;
        width:100%;
    }

    option[value=''] {
        opacity: 0.2;
        color: --color-red;
    }

    textarea {
        display: block;
        appearance: none;
        border: none;
        /* resize: vertical; */
        font-size: 1rem;
        max-width: 100%;
        padding-block: 1.2rem;
    }

    input::placeholder,
    textarea::placeholder {
        opacity: 0.4;
        color: var(--color-blue);
        transition: opacity 100ms linear;
    }

    input:focus,
    textarea:focus {
        outline: 0;
    }

    input:focus::placeholder,
    textarea:focus::placeholder {
        opacity: 0.9;
    }

    .contact-privacy {
        font-size: 14px;
        margin-top: 1rem
    }

    .contact-privacy a {
        text-decoration: underline;
    }

}
