.related-grid {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5rem;
    margin-top: 2rem;
    gap: 3rem;

    & > * {
        position: relative;
        flex: 0 1 100%;
        box-sizing: border-box;

        @media (min-width: 768px) {
            flex: 0 1 46%;
        }

        @media (min-width: 1024px) {
            flex: 0 1 29%;
        }
    }
}

.featured-posts {
    padding-bottom: 0rem !important;
}

.post-related-title {
    margin-bottom: 2rem;
    position: relative;
}

.post-related-title::before {
    content: '';
    position: absolute;
    top: -1.35rem;
    left: 0;
    width: 4rem;
    height: 0.34938rem;
    background-color: #e63214;
}
