@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 700;
    src: url('../../fonts/2f9f83_b_0.woff') format('woff'),
        url('../../fonts/2f9f83_b_0.svg') format('svg'),
        url('../../fonts/2f9f83_b_0.eot') format('embedded-opentype');
}

@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    src: url('../../fonts/2f9f83_5_0.woff') format('woff'),
        url('../../fonts/2f9f83_5_0.svg') format('svg'),
        url('../../fonts/2f9f83_5_0.eot') format('embedded-opentype');
}

@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/2f9f83_9_0.woff') format('woff'),
        url('../../fonts/2f9f83_9_0.svg') format('svg'),
        url('../../fonts/2f9f83_9_0.eot') format('embedded-opentype');
}

@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 100;
    src: url('../../fonts/2f9f83_6_0.woff') format('woff'),
        url('../../fonts/2f9f83_6_0.svg') format('svg'),
        url('../../fonts/2f9f83_6_0.eot') format('embedded-opentype');
}

@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 200;
    src: url('../../fonts/2f9f83_3_0.woff') format('woff'),
        url('../../fonts/2f9f83_3_0.svg') format('svg'),
        url('../../fonts/2f9f83_3_0.eot') format('embedded-opentype');
}
