button {
    border: none;
    background: none;
    color: inherit;
    font-size: inherit;
    font-family: "Avenir", sans-serif;

    &:hover {
        cursor: pointer;
    }
}
