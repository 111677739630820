.usp {
    background-color: var(--color-blue);
    color: var(--color-white);
    padding: 1.5rem;
    margin-top: 3.5rem;
   
    &:first-child {
        margin-top: 0rem;
    }
    position: sticky;
    top: 120px;
    align-self: flex-start;
    
    @media screen and (min-width: 79rem) {
        min-width: 326px;
        width: 100%;
        max-width: 422px;
        box-sizing: border-box;
    }
    ul {
        margin-left: 1rem;
    }

    li {
        list-style-type: disc;
        padding: 0.7rem 0;
        width: 100%;

        font-weight: 100;


        @media screen and (min-width: 79rem) {
            max-width: 300px;
        }

        &.active {
            font-weight: bold;
        }

    }


    h2.bar {
        margin-top: 1rem;
        font-weight: 100;
        font-size: 1.8em;
    }

    span.bold {
        font-weight: 600;
    }

    &__white {
        background-color: white;
        color: var(--color-blue);
        padding:0;
        h2 { margin-bottom:1.5rem;}

        .border {
            border:1px solid var(--color-blue);     
            padding:1.5rem 1.5rem 2.25rem 1.5rem;
        }
    }

    &__compact {
        ul li {margin-bottom:.2em; padding:0;}
    }
}
