/* Disk */
.disks {
    display: grid;
    grid-template-columns: repeat(auto-fill, 6.25rem);
    gap: 2.5rem;
    justify-content: space-between;

    img {
        width: 6.26rem;
        height: 6.25rem;
        display: block;
        object-fit: contain;
        object-position: center center;
        box-sizing: border-box;
    }

    .disk {
        position: relative;

        &::before {
            content: '';
            width: 6.118rem;
            height: 6.118rem;
            position: absolute;
            border: 0.066rem solid currentcolor;
            border-radius: 100%;
            inset: 0 auto auto 0;
            pointer-events: none;
            opacity: 0.2;
        }

        p {
            padding-block-start: 1rem;
            font-size: 0.75rem;
        }
    }
}
