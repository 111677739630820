/*
breakbpoint for smaller devices (720px) and to avoid scaling indefinitly on larger screens (1500px)
KBIA:ideally this goes per component. for now leave it as is.
*/

@media screen and (max-width: 53rem) {
    .header-navigation .navigation-toggle {
        display: initial;
    }
    .header-navigation-list {
        position: absolute;
        inset: var(--height-header) min(-10vw, 43.75rem - 50vw) auto min(-10vw, 43.75rem - 50vw);
        padding-inline: max(10vw, 50vw - 43.75rem);
        transform-origin: 50% 0;
        transform: scaleY(0);
        background-color: var(--color-gray);
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        line-height: 3rem;
        max-height: calc(100vh - var(--height-header));
        padding-block: 1rem;

        &::before {
            content: '';
            display: block;
            background-color: var(--color-red);
            height: 0.35rem;
            width: 4rem;
            box-sizing: content-box;
        }
    }

    .header-navigation.expanded .header-navigation-list {
        transform: scaleY(1);
        transition: transform 150ms linear;
    }
    .header-navigation-list > li {
        padding-inline: 0;
        font-weight: 700;
        align-self:auto;
    }
    .header-navigation-list .sub-navigation-list {
        display: block;
        width: initial;
        position: static;
        font-weight: 400;
        padding: 0;
        border-top:none;
    }
    .header-navigation-list .sub-navigation-list > li {
        line-height: normal;
        align-self: center;
    }
    .header-navigation-list > .navigation-icons {
        inset: 1rem max(10vw, 50vw - 43.75rem) auto auto;
        line-height: normal;
    }
    .header-navigation-list .sub-navigation-list-supplement {
        display: none;
    }
    .header-navigation-list > li:first-child .sub-navigation-list-supplement {
        display: block;
        position: absolute;
        inset: 2rem 0 auto auto;
        padding-block-start: 0;
    }
    .header-navigation-list .navigation-icons {
        display: none;
    }

    .footer-navigation-list {
        flex-wrap: wrap;
    }
    .footer-navigation-list > li:last-child {
        text-align: left;
    }
    .footer-navigation-list > li {
        flex-basis: 50%;
        padding-block-end: 1rem;
    }
}

@media screen and (max-width: 300px) {
    .footer-navigation-list > li {
        flex-basis: 100%;
    }
}

@media screen and (min-width: 53rem) {
    .header-navigation .navigation-toggle {
        display: none;
    }
}

@media screen and (max-width: 53rem) {
    .mobile-hide {
        display: none;
    }
}

@media screen and (min-width: 53rem) {
    .desktop-hide {
      display: none;
    }
  }