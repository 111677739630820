.table-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding-inline: 20px;
}

.table-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--color-blue);
    margin-bottom: 0;
}

table {
    border-collapse: collapse;

    th {
        text-align: left;
    }
    thead th {
        color: var(--color-red);
    }
    tbody th {
        font-weight: 400;
    }
    th,
    td {
        padding: 0.25rem 1rem;
        width: fit-content !important;
    }
    th:first-child,
    td:first-child {
        padding-inline-start: 0;
    }
    th:last-child,
    td:last-child {
        padding-inline-end: 0;
    }
    caption {
        text-align: left;
        font-weight: 700;
        padding-block-end: 2rem;
    }
}
