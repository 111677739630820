header {
    height: var(--height-header);
    background-color: var(--color-white);
    color: var(--color-blue);
    position: fixed;
    inset: 0 0 auto 0;
    z-index: 42069;
    border-block-end: 0.1rem solid var(--color-gray);
    font-size: 0.865rem;

    a:hover,
    a:focus {
        text-decoration: underline;
        text-underline-offset: 0.5em;
    }
}
header.disclaimer a { font-style: normal;}
header.disclaimer  {padding-block: 0;}