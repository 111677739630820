.pill-buttons {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.pill-button {
    text-align: center;
    font-size: 0.75rem;
    line-height: 1;
    border: 0.1em solid currentcolor;
    border-radius: 1.35em;

    &:hover,
    &:focus,
    &:focus-within,
    &.active {
        color: var(--color-white);
        background-color: var(--color-blue);
        border-color: var(--color-blue);

        path {
            fill: var(--color-blue);
        }
    }

    &:hover a,
    &:focus a,
    &:focus-within a,
    &:hover,
    &:focus {
        font-weight: normal;
        text-decoration: none;
    }

    &.blue {
        background-color: var(--color-blue);
        color: var(--color-white);
        border-color: var(--color-blue);

        &:hover,
        &:focus,
        &:focus-within,
        &.active {
            background-color: var(--color-white);
            color: var(--color-blue);
        }
    }

    &.white {
        background-color: var(--color-white);
        color: var(--color-blue);
        border-color: var(--color-white);

        &:hover,
        &:focus,
        &:focus-within,
        &.active {
            background-color: var(--color-blue);
            color: var(--color-white);
        }
    }

    &.red {
        background-color: var(--color-red);
        color: var(--color-white);
        border-color: var(--color-red);

        &:hover,
        &:focus,
        &:focus-within,
        &.active {
            background-color: transparent;
            color: var(--color-red);
        }
    }

    > a,
    > button {
        padding: 0.75em 1.25em;
        display: inline-block;
    }
}

.private-markets-desktop {
    display: none;
}

.private-markets-mobile {
    display: grid;
    gap: 1.5rem;
    padding-block-end: 2rem;

    a {
        font-size: 90%;
        display: block;
    }

    .pill-buttons-select {
        background-image: url('../../assets/images/SVG/arrowdown.svg');
        background-position: right 0.5rem center;
        background-size: 2em 2em;
        background-repeat: no-repeat;
        appearance: none;
        border-color: var(--color-blue);
        border-radius: 1.35em;
        padding: 0.8em;
        position: relative;
        box-sizing: border-box;
        font-family: $main-font;
        color: var(--color-blue);
        background-color: #ffffff;

        &:hover,
        &:focus,
        &:focus-within,
        &.active {
            outline: none;
            border-color: var(--color-blue);
        }
    }
}

@media screen and (min-width: 79rem) {
    .private-markets-mobile {
        display: none;
    }
}

@media screen and (min-width: 79rem) {
    .private-markets-desktop {
        display: flex;
        gap: 1rem;
        padding-block-end: 2rem;
        padding-block-start: 2rem;
        flex-wrap: wrap;
        padding-top: 0;
        padding-bottom: 0;
        position: relative;
        // margin-top: -50px;
        margin-bottom: 50px;

        .pill-buttons {
            gap: 0;
        }

        .pill-button:first-child {
            border-top-left-radius: 1.35em;
            border-bottom-left-radius: 1.35em;
        }

        .pill-button:last-child {
            border-top-right-radius: 1.35em;
            border-bottom-right-radius: 1.35em;
            border-right-width: 1px;
        }

        .pill-button {
            border-radius: 0;
            border-right-width: 0;
        }

        a.pill-button {
            font-weight: bold;
        }

        a.pill-button:last-child {
            border-radius: 1.35em;
            border-width: 2px;
        }
    }

    #mainprivatemarkets .private-markets-desktop {
            margin-top:-90px;
    }
}

#pools-show-open-button,
.private-markets-pools-show-open-button {
    border-color: var(--color-red);
    color: var(--color-blue);
    border-radius: 1.35em;
    border-width: 2px;
    font-weight: bold;

    &:hover {
        color: white;
    }
}

button.pill-button,
.pill-button > button {
    line-height: 1;
}

button.pill-button,
a.pill-button {
    padding: 0.75em 1.25em;
}

@media (max-width: 40rem) {
    .pill-buttons[style^='--'] .pill-button {
        flex: 1 0 var(--pill-button-width, auto);
    }
}
