/* affiliates */
/* ToDo: finish refactoring */
.affiliates {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));

    .affiliate {
        aspect-ratio: 1 / 1;
        color: var(--color-blue);
        position: relative;

        &:hover,
        &:focus-within {
            cursor: pointer;
        }
        img {
            height: 100%;
            width: 100%;
            display: block;
            object-fit: cover;
            object-position: center top;
        }

        .affiliate-name {
            position: absolute;
            inset: auto 0 0.8rem 0;
            text-align: center;
            height: 0.5rem;
            line-height: 0.5rem;
            border-radius: 0.4rem;
            background-color: var(--color-white);
            font-size: 0.5rem;
            margin-inline: auto;
            width: 30%;
            padding-block: 0.15rem;
        }
        .affiliate-last-name {
            display: none;
        }
    }
}

.affiliate:hover .affiliate-last-name,
.affiliate:focus .affiliate-last-name,
.affiliate:focus-within .affiliate-last-name {
    display: initial;
}
.affiliate:hover .affiliate-name,
.affiliate:focus .affiliate-name,
.affiliate:focus-within .affiliate-name {
    //width: max-content;
    padding-inline: 1em;

    /* ToDo Max content is illegal */
}
.affiliate-position,
.affiliate-phone,
.affiliate-mail,
.affiliate-text {
    display: none;
}
.affiliate-last-name {
    display: none;
}

.affiliate:hover .affiliate-last-name,
.affiliate:focus .affiliate-last-name,
.affiliate:focus-within .affiliate-last-name {
    display: initial;
}

.affiliate:hover .affiliate-name,
.affiliate:focus .affiliate-name,
.affiliate:focus-within .affiliate-name {
    width: max-content;
    padding-inline: 1em;
}

.affiliate-position,
.affiliate-phone,
.affiliate-mail,
.affiliate-text {
    display: none;
}
.affiliate-modal {
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    inset: 0;
    z-index: 2;
    background-color: rgba(32, 42, 82, 0.9);
    color: var(--color-white);
    align-content: center;
    justify-content: center;
    padding: 2rem;
}

.affiliate-modal img {
    display: block;
    width: initial;
    max-width: 20rem;
    max-height: 20rem;
    flex-basis: 20rem;
    flex-grow: 1;
    aspect-ratio: 1/1;
    height: auto;
}
.affiliate-info-wrapper {
    flex-basis: 20rem;
    flex-grow: 1;
    aspect-ratio: 1/1;
    height: initial;
    max-height: 20rem;
    max-width: 20rem;
    overflow-y: scroll;
    padding: 1rem;
    background-color: var(--color-white);
    color: var(--color-blue);
    box-sizing: border-box;
    font-size: 0.8rem;
}

.affiliate-modal-name {
    font-weight: 700;
    padding-block-end: 1rem;
}
.affiliate-modal-text {
    padding-block-start: 1rem;
}
.affiliate-modal button {
    position: absolute;
    inset: 0 0 auto auto;
    padding: 0.5rem;
    font-size: 2rem;
    text-shadow: 0.1rem 0.1rem rgba(32, 42, 82, 0.2), -0.1rem 0.1rem rgba(32, 42, 82, 0.2),
        0.1rem -0.1rem rgba(32, 42, 82, 0.2), -0.1rem -0.1rem rgba(32, 42, 82, 0.2);
}
.strip .affiliates {
    position: relative;
    inset-inline-start: min(-10vw, 43.75rem - 50vw);
    width: 100vw;
}
