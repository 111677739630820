.choose-language {
    padding-inline: max(10vw, 50vw - 43.75rem);
    padding-top: 3vw;

    h1 {
        min-height: 6.5rem;
    }

    li {
        margin-top: 1rem;
    }
}
