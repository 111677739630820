dialog {
    border: none;
    max-width: min(80vw, 109.375rem);
    margin: auto;
    padding: 4rem;
    color: var(--color-blue);

    &::backdrop {
        background-color: rgba(32, 42, 82, 0.8);
    }

    button.close-dialog {
        position: absolute;
        inset: 2rem 2rem auto auto;
        height: 1.2rem;
        width: 1.2rem;
        padding-inline-start: 1.2rem;
        overflow: hidden;
        background-image: linear-gradient(
                45deg,
                transparent 45%,
                var(--color-blue) 45% 55%,
                transparent 55%
            ),
            linear-gradient(-45deg, transparent 45%, var(--color-blue) 45% 55%, transparent 55%);
    }

    button.close-dialog:focus {
        outline: none;
    }
}
