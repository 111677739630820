.ticker-tape {
    position: relative;
    display: flex;
    height: 10rem;
    margin: 0 auto;
    overflow: hidden;
    width: min(100vw, 150rem);
    /* opacity: 0; */
    z-index: -1;

    li {
        position: absolute;
        inset: 0 auto 0 -10rem;
        flex: 0 0 10rem;
        height: 100%;
        animation-name: taperoll;
        animation-duration: 203000ms;
        transform-box: border-box;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        padding-inline-end: 10rem;
    }

    li:nth-child(30n + 1) {
        animation-delay: -203000ms;
    }
    li:nth-child(30n + 2) {
        animation-delay: -196000ms;
    }
    li:nth-child(30n + 3) {
        animation-delay: -189000ms;
    }
    li:nth-child(30n + 4) {
        animation-delay: -182000ms;
    }
    li:nth-child(30n + 5) {
        animation-delay: -175000ms;
    }
    li:nth-child(30n + 6) {
        animation-delay: -168000ms;
    }
    li:nth-child(30n + 7) {
        animation-delay: -161000ms;
    }
    li:nth-child(30n + 8) {
        animation-delay: -154000ms;
    }
    li:nth-child(30n + 9) {
        animation-delay: -147000ms;
    }
    li:nth-child(30n + 10) {
        animation-delay: -140000ms;
    }
    li:nth-child(30n + 11) {
        animation-delay: -133000ms;
    }
    li:nth-child(30n + 12) {
        animation-delay: -126000ms;
    }
    li:nth-child(30n + 13) {
        animation-delay: -119000ms;
    }
    li:nth-child(30n + 14) {
        animation-delay: -112000ms;
    }
    li:nth-child(30n + 15) {
        animation-delay: -105000ms;
    }
    li:nth-child(30n + 16) {
        animation-delay: -98000ms;
    }
    li:nth-child(30n + 17) {
        animation-delay: -91000ms;
    }
    li:nth-child(30n + 18) {
        animation-delay: -84000ms;
    }
    li:nth-child(30n + 19) {
        animation-delay: -77000ms;
    }
    li:nth-child(30n + 20) {
        animation-delay: -70000ms;
    }
    li:nth-child(30n + 21) {
        animation-delay: -63000ms;
    }
    li:nth-child(30n + 22) {
        animation-delay: -56000ms;
    }
    li:nth-child(30n + 23) {
        animation-delay: -49000ms;
    }
    li:nth-child(30n + 24) {
        animation-delay: -42000ms;
    }
    li:nth-child(30n + 25) {
        animation-delay: -35000ms;
    }
    li:nth-child(30n + 26) {
        animation-delay: -28000ms;
    }
    li:nth-child(30n + 27) {
        animation-delay: -21000ms;
    }
    li:nth-child(30n + 28) {
        animation-delay: -14000ms;
    }
    li:nth-child(30n + 29) {
        animation-delay: -7000ms;
    }
    li:nth-child(30n + 30) {
        animation-delay: 0ms;
    }

    img {
        width: 10rem;
        height: 100%;
        display: block;
        object-fit: contain;
        object-position: 0 0;
    }
}
.strip .ticker-tape {
    inset-inline-start: max(-10vw, -15rem);
}

@media screen and (min-width: 150rem) {
    .ticker-tape::before {
        content: '';
        z-index: 1;
        position: absolute;
        inset: 0 auto 0 0;
        width: 10.9375rem;
        background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
        );
    }
    .ticker-tape::after {
        content: '';
        position: absolute;
        inset: 0 0 0 auto;
        width: 10.9375rem;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    }
}

@keyframes taperoll {
    from {
        transform: translatex(calc(2900%));
    }
    to {
        transform: translatex(0);
    }
}
