/*
This file is used to contain all abstracts imports.
Files inside this folder can contain abstract settings, helpers or functions. They have no direct output.
*/
@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/2f9f83_b_0.woff") format("woff"), url("../../fonts/2f9f83_b_0.svg") format("svg"), url("../../fonts/2f9f83_b_0.eot") format("embedded-opentype");
}
@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 900;
  src: url("../../fonts/2f9f83_5_0.woff") format("woff"), url("../../fonts/2f9f83_5_0.svg") format("svg"), url("../../fonts/2f9f83_5_0.eot") format("embedded-opentype");
}
@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/2f9f83_9_0.woff") format("woff"), url("../../fonts/2f9f83_9_0.svg") format("svg"), url("../../fonts/2f9f83_9_0.eot") format("embedded-opentype");
}
@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 100;
  src: url("../../fonts/2f9f83_6_0.woff") format("woff"), url("../../fonts/2f9f83_6_0.svg") format("svg"), url("../../fonts/2f9f83_6_0.eot") format("embedded-opentype");
}
@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 200;
  src: url("../../fonts/2f9f83_3_0.woff") format("woff"), url("../../fonts/2f9f83_3_0.svg") format("svg"), url("../../fonts/2f9f83_3_0.eot") format("embedded-opentype");
}
/*
This file is used to contain all base imports.
Files inside this folder can contain global styles used in the project.
*/
/* link behaviour */
a {
  text-decoration: none;
  color: inherit;
  text-underline-offset: 0.25em;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus {
  text-decoration: underline;
}

.content-left p a {
  text-decoration: underline;
}

h1 {
  font-size: calc(1rem + 1.9vw);
  font-weight: 400;
  min-height: 12.5rem;
}

h2 {
  font-weight: 400;
  font-size: calc(1.5rem + 0.5vw);
  line-height: 1.2;
}

.disclaimer {
  font-size: 14px;
  padding-block: 1rem;
  font-style: italic;
}

/* Bar is applied to heading elements to create the red bar. */
.bar::before {
  content: "";
  display: block;
  background-color: var(--color-red);
  height: 0.35rem;
  width: 4rem;
  margin-bottom: 0.75rem;
}

/* additional section styling for typo elements */
section > p:not(:last-child),
section > h3:not(:last-child),
section > ul:not(:last-child),
section > ol:not(:last-child),
section > figure:not(:last-child) {
  padding-block-end: 2rem;
}

.capitalize::first-letter {
  text-transform: capitalize;
}

/*
breakbpoint for smaller devices (720px) and to avoid scaling indefinitly on larger screens (1500px)
KBIA:ideally this goes per component. for now leave it as is.
*/
@media screen and (max-width: 53rem) {
  .header-navigation .navigation-toggle {
    display: initial;
  }
  .header-navigation-list {
    position: absolute;
    inset: var(--height-header) min(-10vw, 43.75rem - 50vw) auto min(-10vw, 43.75rem - 50vw);
    padding-inline: max(10vw, 50vw - 43.75rem);
    transform-origin: 50% 0;
    transform: scaleY(0);
    background-color: var(--color-gray);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    line-height: 3rem;
    max-height: calc(100vh - var(--height-header));
    padding-block: 1rem;
  }
  .header-navigation-list::before {
    content: "";
    display: block;
    background-color: var(--color-red);
    height: 0.35rem;
    width: 4rem;
    box-sizing: content-box;
  }
  .header-navigation.expanded .header-navigation-list {
    transform: scaleY(1);
    transition: transform 150ms linear;
  }
  .header-navigation-list > li {
    padding-inline: 0;
    font-weight: 700;
    align-self: auto;
  }
  .header-navigation-list .sub-navigation-list {
    display: block;
    width: initial;
    position: static;
    font-weight: 400;
    padding: 0;
    border-top: none;
  }
  .header-navigation-list .sub-navigation-list > li {
    line-height: normal;
    align-self: center;
  }
  .header-navigation-list > .navigation-icons {
    inset: 1rem max(10vw, 50vw - 43.75rem) auto auto;
    line-height: normal;
  }
  .header-navigation-list .sub-navigation-list-supplement {
    display: none;
  }
  .header-navigation-list > li:first-child .sub-navigation-list-supplement {
    display: block;
    position: absolute;
    inset: 2rem 0 auto auto;
    padding-block-start: 0;
  }
  .header-navigation-list .navigation-icons {
    display: none;
  }
  .footer-navigation-list {
    flex-wrap: wrap;
  }
  .footer-navigation-list > li:last-child {
    text-align: left;
  }
  .footer-navigation-list > li {
    flex-basis: 50%;
    padding-block-end: 1rem;
  }
}
@media screen and (max-width: 300px) {
  .footer-navigation-list > li {
    flex-basis: 100%;
  }
}
@media screen and (min-width: 53rem) {
  .header-navigation .navigation-toggle {
    display: none;
  }
}
@media screen and (max-width: 53rem) {
  .mobile-hide {
    display: none;
  }
}
@media screen and (min-width: 53rem) {
  .desktop-hide {
    display: none;
  }
}
/* Additonal Base Stylesheets */
:root {
  --color-blue: rgb(32, 42, 82);
  --color-lightgray: rgb(238, 238, 238);
  --color-red: rgb(230, 50, 20);
  --color-browngray: rgb(90, 80, 70);
  --color-darkgray: rgb(100, 100, 100);
  --color-gray: rgb(220, 220, 220);
  --color-white: #ffffff;
  --height-header: 6.25rem;
  font-size: 16px;
  font-family: "Avenir", sans-serif;
}

* {
  padding: 0;
  margin: 0;
}

html {
  scroll-padding-block-start: var(--height-header);
  overflow-x: hidden;
}

body {
  line-height: 1.5;
  color: var(--color-blue);
  font-family: "Avenir", sans-serif;
}

.mb-0 {
  margin-bottom: 0 !important;
  padding-block-end: 0 !important;
}

/*
This file is used to contain all component imports.
Files inside this folder should contain all styles relating to a reusable component.
*/
/* affiliates */
/* ToDo: finish refactoring */
.affiliates {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
}
.affiliates .affiliate {
  aspect-ratio: 1/1;
  color: var(--color-blue);
  position: relative;
}
.affiliates .affiliate:hover, .affiliates .affiliate:focus-within {
  cursor: pointer;
}
.affiliates .affiliate img {
  height: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
  object-position: center top;
}
.affiliates .affiliate .affiliate-name {
  position: absolute;
  inset: auto 0 0.8rem 0;
  text-align: center;
  height: 0.5rem;
  line-height: 0.5rem;
  border-radius: 0.4rem;
  background-color: var(--color-white);
  font-size: 0.5rem;
  margin-inline: auto;
  width: 30%;
  padding-block: 0.15rem;
}
.affiliates .affiliate .affiliate-last-name {
  display: none;
}

.affiliate:hover .affiliate-last-name,
.affiliate:focus .affiliate-last-name,
.affiliate:focus-within .affiliate-last-name {
  display: initial;
}

.affiliate:hover .affiliate-name,
.affiliate:focus .affiliate-name,
.affiliate:focus-within .affiliate-name {
  padding-inline: 1em;
  /* ToDo Max content is illegal */
}

.affiliate-position,
.affiliate-phone,
.affiliate-mail,
.affiliate-text {
  display: none;
}

.affiliate-last-name {
  display: none;
}

.affiliate:hover .affiliate-last-name,
.affiliate:focus .affiliate-last-name,
.affiliate:focus-within .affiliate-last-name {
  display: initial;
}

.affiliate:hover .affiliate-name,
.affiliate:focus .affiliate-name,
.affiliate:focus-within .affiliate-name {
  width: max-content;
  padding-inline: 1em;
}

.affiliate-position,
.affiliate-phone,
.affiliate-mail,
.affiliate-text {
  display: none;
}

.affiliate-modal {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  inset: 0;
  z-index: 2;
  background-color: rgba(32, 42, 82, 0.9);
  color: var(--color-white);
  align-content: center;
  justify-content: center;
  padding: 2rem;
}

.affiliate-modal img {
  display: block;
  width: initial;
  max-width: 20rem;
  max-height: 20rem;
  flex-basis: 20rem;
  flex-grow: 1;
  aspect-ratio: 1/1;
  height: auto;
}

.affiliate-info-wrapper {
  flex-basis: 20rem;
  flex-grow: 1;
  aspect-ratio: 1/1;
  height: initial;
  max-height: 20rem;
  max-width: 20rem;
  overflow-y: scroll;
  padding: 1rem;
  background-color: var(--color-white);
  color: var(--color-blue);
  box-sizing: border-box;
  font-size: 0.8rem;
}

.affiliate-modal-name {
  font-weight: 700;
  padding-block-end: 1rem;
}

.affiliate-modal-text {
  padding-block-start: 1rem;
}

.affiliate-modal button {
  position: absolute;
  inset: 0 0 auto auto;
  padding: 0.5rem;
  font-size: 2rem;
  text-shadow: 0.1rem 0.1rem rgba(32, 42, 82, 0.2), -0.1rem 0.1rem rgba(32, 42, 82, 0.2), 0.1rem -0.1rem rgba(32, 42, 82, 0.2), -0.1rem -0.1rem rgba(32, 42, 82, 0.2);
}

.strip .affiliates {
  position: relative;
  inset-inline-start: min(-10vw, 43.75rem - 50vw);
  width: 100vw;
}

button {
  border: none;
  background: none;
  color: inherit;
  font-size: inherit;
  font-family: "Avenir", sans-serif;
}
button:hover {
  cursor: pointer;
}

.choose-language {
  padding-inline: max(10vw, 50vw - 43.75rem);
  padding-top: 3vw;
}
.choose-language h1 {
  min-height: 6.5rem;
}
.choose-language li {
  margin-top: 1rem;
}

dialog {
  border: none;
  max-width: min(80vw, 109.375rem);
  margin: auto;
  padding: 4rem;
  color: var(--color-blue);
}
dialog::backdrop {
  background-color: rgba(32, 42, 82, 0.8);
}
dialog button.close-dialog {
  position: absolute;
  inset: 2rem 2rem auto auto;
  height: 1.2rem;
  width: 1.2rem;
  padding-inline-start: 1.2rem;
  overflow: hidden;
  background-image: linear-gradient(45deg, transparent 45%, var(--color-blue) 45% 55%, transparent 55%), linear-gradient(-45deg, transparent 45%, var(--color-blue) 45% 55%, transparent 55%);
}
dialog button.close-dialog:focus {
  outline: none;
}

/* Disk */
.disks {
  display: grid;
  grid-template-columns: repeat(auto-fill, 6.25rem);
  gap: 2.5rem;
  justify-content: space-between;
}
.disks img {
  width: 6.26rem;
  height: 6.25rem;
  display: block;
  object-fit: contain;
  object-position: center center;
  box-sizing: border-box;
}
.disks .disk {
  position: relative;
}
.disks .disk::before {
  content: "";
  width: 6.118rem;
  height: 6.118rem;
  position: absolute;
  border: 0.066rem solid currentcolor;
  border-radius: 100%;
  inset: 0 auto auto 0;
  pointer-events: none;
  opacity: 0.2;
}
.disks .disk p {
  padding-block-start: 1rem;
  font-size: 0.75rem;
}

.assistive,
label,
legend {
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.inline-form {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
}
.inline-form label {
  overflow: initial;
  padding-left: 1rem;
  font-size: 0.85rem;
  position: relative;
  left: 5.7px;
  white-space: unset;
  width: 100% !important;
}
.inline-form input {
  flex: inherit;
  align-self: center;
}

form button[type=submit] {
  margin-block-start: 2rem;
}
form fieldset {
  appearance: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 0;
}
form input {
  border: none;
  font-size: 0.75rem;
}
form input[type=text],
form input[type=tel],
form input[type=email] {
  border: 0.1em solid var(--color-blue);
  padding: 0.5em 1em;
  line-height: 1;
  height: 1em;
  border-radius: 1.1em;
}
form select {
  appearance: none;
  border: 0.1em solid var(--color-blue);
  padding: 0.5em 1em;
  border-radius: 1.25em;
  color: var(--color-blue);
  font-size: 0.75rem;
  background-color: transparent;
  cursor: pointer;
  font-family: "Avenir", arial;
  width: 100%;
}
form option[value=""] {
  opacity: 0.2;
  color: --color-red;
}
form textarea {
  display: block;
  appearance: none;
  border: none;
  /* resize: vertical; */
  font-size: 1rem;
  max-width: 100%;
  padding-block: 1.2rem;
}
form input::placeholder,
form textarea::placeholder {
  opacity: 0.4;
  color: var(--color-blue);
  transition: opacity 100ms linear;
}
form input:focus,
form textarea:focus {
  outline: 0;
}
form input:focus::placeholder,
form textarea:focus::placeholder {
  opacity: 0.9;
}
form .contact-privacy {
  font-size: 14px;
  margin-top: 1rem;
}
form .contact-privacy a {
  text-decoration: underline;
}

img {
  display: block;
  max-width: 100%;
}

figcaption + img {
  padding-block-start: 1rem;
}

figcaption strong {
  font-weight: 700;
  display: block;
}
figcaption + img {
  padding-block-start: 1rem;
}

ul {
  list-style: none;
}

.pill-buttons {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.pill-button {
  text-align: center;
  font-size: 0.75rem;
  line-height: 1;
  border: 0.1em solid currentcolor;
  border-radius: 1.35em;
}
.pill-button:hover, .pill-button:focus, .pill-button:focus-within, .pill-button.active {
  color: var(--color-white);
  background-color: var(--color-blue);
  border-color: var(--color-blue);
}
.pill-button:hover path, .pill-button:focus path, .pill-button:focus-within path, .pill-button.active path {
  fill: var(--color-blue);
}
.pill-button:hover a, .pill-button:focus a, .pill-button:focus-within a, .pill-button:hover, .pill-button:focus {
  font-weight: normal;
  text-decoration: none;
}
.pill-button.blue {
  background-color: var(--color-blue);
  color: var(--color-white);
  border-color: var(--color-blue);
}
.pill-button.blue:hover, .pill-button.blue:focus, .pill-button.blue:focus-within, .pill-button.blue.active {
  background-color: var(--color-white);
  color: var(--color-blue);
}
.pill-button.white {
  background-color: var(--color-white);
  color: var(--color-blue);
  border-color: var(--color-white);
}
.pill-button.white:hover, .pill-button.white:focus, .pill-button.white:focus-within, .pill-button.white.active {
  background-color: var(--color-blue);
  color: var(--color-white);
}
.pill-button.red {
  background-color: var(--color-red);
  color: var(--color-white);
  border-color: var(--color-red);
}
.pill-button.red:hover, .pill-button.red:focus, .pill-button.red:focus-within, .pill-button.red.active {
  background-color: transparent;
  color: var(--color-red);
}
.pill-button > a,
.pill-button > button {
  padding: 0.75em 1.25em;
  display: inline-block;
}

.private-markets-desktop {
  display: none;
}

.private-markets-mobile {
  display: grid;
  gap: 1.5rem;
  padding-block-end: 2rem;
}
.private-markets-mobile a {
  font-size: 90%;
  display: block;
}
.private-markets-mobile .pill-buttons-select {
  background-image: url("../../assets/images/SVG/arrowdown.svg");
  background-position: right 0.5rem center;
  background-size: 2em 2em;
  background-repeat: no-repeat;
  appearance: none;
  border-color: var(--color-blue);
  border-radius: 1.35em;
  padding: 0.8em;
  position: relative;
  box-sizing: border-box;
  font-family: "Avenir", sans-serif;
  color: var(--color-blue);
  background-color: #ffffff;
}
.private-markets-mobile .pill-buttons-select:hover, .private-markets-mobile .pill-buttons-select:focus, .private-markets-mobile .pill-buttons-select:focus-within, .private-markets-mobile .pill-buttons-select.active {
  outline: none;
  border-color: var(--color-blue);
}

@media screen and (min-width: 79rem) {
  .private-markets-mobile {
    display: none;
  }
}
@media screen and (min-width: 79rem) {
  .private-markets-desktop {
    display: flex;
    gap: 1rem;
    padding-block-end: 2rem;
    padding-block-start: 2rem;
    flex-wrap: wrap;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    margin-bottom: 50px;
  }
  .private-markets-desktop .pill-buttons {
    gap: 0;
  }
  .private-markets-desktop .pill-button:first-child {
    border-top-left-radius: 1.35em;
    border-bottom-left-radius: 1.35em;
  }
  .private-markets-desktop .pill-button:last-child {
    border-top-right-radius: 1.35em;
    border-bottom-right-radius: 1.35em;
    border-right-width: 1px;
  }
  .private-markets-desktop .pill-button {
    border-radius: 0;
    border-right-width: 0;
  }
  .private-markets-desktop a.pill-button {
    font-weight: bold;
  }
  .private-markets-desktop a.pill-button:last-child {
    border-radius: 1.35em;
    border-width: 2px;
  }
  #mainprivatemarkets .private-markets-desktop {
    margin-top: -90px;
  }
}
#pools-show-open-button,
.private-markets-pools-show-open-button {
  border-color: var(--color-red);
  color: var(--color-blue);
  border-radius: 1.35em;
  border-width: 2px;
  font-weight: bold;
}
#pools-show-open-button:hover,
.private-markets-pools-show-open-button:hover {
  color: white;
}

button.pill-button,
.pill-button > button {
  line-height: 1;
}

button.pill-button,
a.pill-button {
  padding: 0.75em 1.25em;
}

@media (max-width: 40rem) {
  .pill-buttons[style^="--"] .pill-button {
    flex: 1 0 var(--pill-button-width, auto);
  }
}
q:before {
  content: open-quote;
}
q:after {
  content: open-quote;
}

.quotee {
  display: block;
  padding-block-start: 0.5rem;
  font-size: 75%;
}
@media screen and (min-width: 79rem) {
  .quotee {
    font-size: 0.375em;
  }
}
.quotee.inline {
  display: inline;
}

/* SVG */
/*------------------------------------------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------------------------------------------*/
/*WMP Logo*/
.wcls-1 {
  fill: #202a52;
}

.wcls-2 {
  fill: #fff;
}

/*WMPS Logo*/
.wscls-1 {
  fill: #202a52;
}

.wscls-2 {
  fill: #fff;
}

.navicon {
  fill: #202a52;
}

.navigation-icon-list a:hover .navicon {
  fill: #e63214;
}

.table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-inline: 20px;
}

.table-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--color-blue);
  margin-bottom: 0;
}

table {
  border-collapse: collapse;
}
table th {
  text-align: left;
}
table thead th {
  color: var(--color-red);
}
table tbody th {
  font-weight: 400;
}
table th,
table td {
  padding: 0.25rem 1rem;
  width: fit-content !important;
}
table th:first-child,
table td:first-child {
  padding-inline-start: 0;
}
table th:last-child,
table td:last-child {
  padding-inline-end: 0;
}
table caption {
  text-align: left;
  font-weight: 700;
  padding-block-end: 2rem;
}

/* tally meter */
.tally-meters {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-content: flex-start;
  flex-direction: column;
}
.tally-meters > li {
  display: flex;
  align-content: center;
}
.tally-meters.column {
  flex-grow: 0.66;
  min-width: 10rem;
  max-width: 29.1rem;
}
.tally-meters .tally-meter {
  font-size: 1.5rem;
  height: 4rem;
  width: 4rem;
  border: 0.066rem solid currentcolor;
  border-radius: 100%;
  line-height: 4rem;
  text-align: center;
  margin-inline-end: 1rem;
  flex-shrink: 0;
}
.tally-meters .tally-meter + span {
  align-self: center;
  font-size: 0.75rem;
}

#main .tally-meters {
  margin-top: 0vw;
  padding-top: 2rem;
}

#vermogensbeheer .tally-meters {
  margin-top: 7vw;
}

.ticker-tape {
  position: relative;
  display: flex;
  height: 10rem;
  margin: 0 auto;
  overflow: hidden;
  width: min(100vw, 150rem);
  /* opacity: 0; */
  z-index: -1;
}
.ticker-tape li {
  position: absolute;
  inset: 0 auto 0 -10rem;
  flex: 0 0 10rem;
  height: 100%;
  animation-name: taperoll;
  animation-duration: 203000ms;
  transform-box: border-box;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  padding-inline-end: 10rem;
}
.ticker-tape li:nth-child(30n+1) {
  animation-delay: -203000ms;
}
.ticker-tape li:nth-child(30n+2) {
  animation-delay: -196000ms;
}
.ticker-tape li:nth-child(30n+3) {
  animation-delay: -189000ms;
}
.ticker-tape li:nth-child(30n+4) {
  animation-delay: -182000ms;
}
.ticker-tape li:nth-child(30n+5) {
  animation-delay: -175000ms;
}
.ticker-tape li:nth-child(30n+6) {
  animation-delay: -168000ms;
}
.ticker-tape li:nth-child(30n+7) {
  animation-delay: -161000ms;
}
.ticker-tape li:nth-child(30n+8) {
  animation-delay: -154000ms;
}
.ticker-tape li:nth-child(30n+9) {
  animation-delay: -147000ms;
}
.ticker-tape li:nth-child(30n+10) {
  animation-delay: -140000ms;
}
.ticker-tape li:nth-child(30n+11) {
  animation-delay: -133000ms;
}
.ticker-tape li:nth-child(30n+12) {
  animation-delay: -126000ms;
}
.ticker-tape li:nth-child(30n+13) {
  animation-delay: -119000ms;
}
.ticker-tape li:nth-child(30n+14) {
  animation-delay: -112000ms;
}
.ticker-tape li:nth-child(30n+15) {
  animation-delay: -105000ms;
}
.ticker-tape li:nth-child(30n+16) {
  animation-delay: -98000ms;
}
.ticker-tape li:nth-child(30n+17) {
  animation-delay: -91000ms;
}
.ticker-tape li:nth-child(30n+18) {
  animation-delay: -84000ms;
}
.ticker-tape li:nth-child(30n+19) {
  animation-delay: -77000ms;
}
.ticker-tape li:nth-child(30n+20) {
  animation-delay: -70000ms;
}
.ticker-tape li:nth-child(30n+21) {
  animation-delay: -63000ms;
}
.ticker-tape li:nth-child(30n+22) {
  animation-delay: -56000ms;
}
.ticker-tape li:nth-child(30n+23) {
  animation-delay: -49000ms;
}
.ticker-tape li:nth-child(30n+24) {
  animation-delay: -42000ms;
}
.ticker-tape li:nth-child(30n+25) {
  animation-delay: -35000ms;
}
.ticker-tape li:nth-child(30n+26) {
  animation-delay: -28000ms;
}
.ticker-tape li:nth-child(30n+27) {
  animation-delay: -21000ms;
}
.ticker-tape li:nth-child(30n+28) {
  animation-delay: -14000ms;
}
.ticker-tape li:nth-child(30n+29) {
  animation-delay: -7000ms;
}
.ticker-tape li:nth-child(30n+30) {
  animation-delay: 0ms;
}
.ticker-tape img {
  width: 10rem;
  height: 100%;
  display: block;
  object-fit: contain;
  object-position: 0 0;
}

.strip .ticker-tape {
  inset-inline-start: max(-10vw, -15rem);
}

@media screen and (min-width: 150rem) {
  .ticker-tape::before {
    content: "";
    z-index: 1;
    position: absolute;
    inset: 0 auto 0 0;
    width: 10.9375rem;
    background-image: linear-gradient(to right, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
  }
  .ticker-tape::after {
    content: "";
    position: absolute;
    inset: 0 0 0 auto;
    width: 10.9375rem;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255));
  }
}
@keyframes taperoll {
  from {
    transform: translatex(2900%);
  }
  to {
    transform: translatex(0);
  }
}
.usp {
  background-color: var(--color-blue);
  color: var(--color-white);
  padding: 1.5rem;
  margin-top: 3.5rem;
  position: sticky;
  top: 120px;
  align-self: flex-start;
}
.usp:first-child {
  margin-top: 0rem;
}
@media screen and (min-width: 79rem) {
  .usp {
    min-width: 326px;
    width: 100%;
    max-width: 422px;
    box-sizing: border-box;
  }
}
.usp ul {
  margin-left: 1rem;
}
.usp li {
  list-style-type: disc;
  padding: 0.7rem 0;
  width: 100%;
  font-weight: 100;
}
@media screen and (min-width: 79rem) {
  .usp li {
    max-width: 300px;
  }
}
.usp li.active {
  font-weight: bold;
}
.usp h2.bar {
  margin-top: 1rem;
  font-weight: 100;
  font-size: 1.8em;
}
.usp span.bold {
  font-weight: 600;
}
.usp__white {
  background-color: white;
  color: var(--color-blue);
  padding: 0;
}
.usp__white h2 {
  margin-bottom: 1.5rem;
}
.usp__white .border {
  border: 1px solid var(--color-blue);
  padding: 1.5rem 1.5rem 2.25rem 1.5rem;
}
.usp__compact ul li {
  margin-bottom: 0.2em;
  padding: 0;
}

.contact-pool-alert {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  margin-top: 4rem;
  padding-inline: max(10vw, 50vw - 43.75rem);
  grid-area: poolalert;
}
.contact-pool-alert .contact-pool-alert-content {
  position: relative;
  padding-inline: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}
.contact-pool-alert .contact-pool-alert-content .contact-pool-alert-content-card {
  height: 100%;
  background-color: white;
}
.contact-pool-alert .contact-pool-alert-content .contact-pool-alert-content-card .contact-pool-alert-title {
  margin-top: 4rem;
  position: relative;
}
.contact-pool-alert .contact-pool-alert-content .contact-pool-alert-content-card .contact-pool-alert-title::before {
  content: "";
  position: absolute;
  top: -1rem;
  left: 0;
  width: 4rem;
  height: 0.34938rem;
  background-color: #e63214;
}
.contact-pool-alert .contact-pool-alert-content .contact-pool-alert-content-card .contact-pool-alert-text {
  margin-block: 2rem;
}
.contact-pool-alert .contact-pool-alert-content .contact-pool-alert-content-card .contact-pool-alert-privacy {
  margin-top: 2rem;
  font-size: 14px;
}
.contact-pool-alert .contact-pool-alert-content .contact-pool-alert-content-card .contact-pool-alert-privacy a {
  text-decoration: underline;
}
.contact-pool-alert img.contact-pool-alert-image-desktop {
  padding-inline: 0;
  display: none;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
}
.contact-pool-alert img.contact-pool-alert-image-moblile {
  display: block;
  object-fit: cover;
}
@media screen and (min-width: 45rem) {
  .contact-pool-alert {
    margin: 0 auto;
    padding-inline: 0;
    width: 80%;
    flex-direction: unset;
    height: 44rem;
  }
  .contact-pool-alert img.contact-pool-alert-image-desktop {
    display: block;
  }
  .contact-pool-alert img.contact-pool-alert-image-moblile {
    display: none;
  }
  .contact-pool-alert .contact-pool-alert-content {
    position: absolute;
  }
  .contact-pool-alert .contact-pool-alert-content-card {
    margin-inline: 5rem;
    padding-inline: 0;
    min-height: 70%;
    height: unset !important;
    width: 75%;
    max-height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contact-pool-alert .contact-pool-alert-content-card-body {
    margin-inline: 3rem;
  }
}
@media screen and (min-width: 1024px) {
  .contact-pool-alert .contact-pool-alert-content-card {
    width: 65%;
  }
}
@media screen and (min-width: 1280px) {
  .contact-pool-alert {
    width: 100%;
  }
  .contact-pool-alert img.contact-pool-alert-image-desktop {
    padding-inline: max(10vw, 50vw - 43.75rem);
  }
  .contact-pool-alert .contact-pool-alert-content {
    padding-inline: max(10vw, 50vw - 43.75rem);
  }
  .contact-pool-alert .contact-pool-alert-content-card {
    width: 37%;
  }
}
.contact-pool-alert .contact-pool-alert-button {
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.blog-header {
  margin-top: 5.25rem;
  height: 16.5rem;
  padding: 1.5rem;
  color: #fff;
  background-color: #202a52;
  display: flex;
  align-items: center;
}

.blog-header-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  position: relative;
  padding: 1.5rem;
  margin-bottom: 4.5rem;
}
@media (min-width: 640px) {
  .blog-header-container {
    max-width: 480px;
  }
}
@media (min-width: 768px) {
  .blog-header-container {
    max-width: 580px;
  }
}
@media (min-width: 1024px) {
  .blog-header-container {
    max-width: 780px;
  }
}
@media (min-width: 1280px) {
  .blog-header-container {
    max-width: 980px;
  }
}
@media (min-width: 1536px) {
  .blog-header-container {
    max-width: 1180px;
  }
}

.blog-header-title {
  position: relative;
  min-height: unset;
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .blog-header-title {
    font-size: 2.5rem;
    line-height: 3.75rem;
  }
}
@media (max-width: 576px) {
  .blog-header-title {
    font-size: 2rem;
    line-height: 3rem;
  }
}
@media (max-width: 320px) {
  .blog-header-title {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
}

.blog-header-title::before {
  content: "";
  position: absolute;
  top: -1.3rem;
  left: 0;
  width: 4rem;
  height: 0.34938rem;
  background-color: #e63214;
}

.blog-header-subtitle {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.85rem;
}
@media (max-width: 768px) {
  .blog-header-subtitle {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
@media (max-width: 576px) {
  .blog-header-subtitle {
    font-size: 0.9rem;
    line-height: 1.35rem;
  }
}
@media (max-width: 320px) {
  .blog-header-subtitle {
    font-size: 0.8rem;
    line-height: 1.2rem;
  }
}

.blog-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0rem;
  position: relative;
  padding: 1.5rem;
  margin-bottom: 4.5rem;
}
@media (min-width: 640px) {
  .blog-container {
    max-width: 480px;
  }
}
@media (min-width: 768px) {
  .blog-container {
    max-width: 580px;
  }
}
@media (min-width: 1024px) {
  .blog-container {
    max-width: 780px;
  }
}
@media (min-width: 1280px) {
  .blog-container {
    max-width: 980px;
  }
}
@media (min-width: 1536px) {
  .blog-container {
    max-width: 1180px;
  }
}

.contact-container {
  display: flex;
  flex-wrap: nowrap !important;
  gap: 6rem;
}
.contact-container .contact {
  width: 50%;
}
.contact-container .contact-image {
  width: 50%;
}
.contact-container .contact-image img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  aspect-ratio: 1/1;
}
.contact-container .contact-text {
  margin-top: 2rem;
}
.contact-container .contact-text p {
  margin-bottom: 2rem;
}
@media (max-width: 1024px) {
  .contact-container {
    flex-direction: column;
    gap: 0;
  }
  .contact-container .contact {
    width: 100%;
  }
  .contact-container .contact-image {
    margin-top: 2rem;
    width: 100%;
  }
  .contact-container .contact-image img {
    width: 100%;
  }
}

.cards-grid {
  display: flex;
  flex-wrap: wrap;
}
.cards-grid > * {
  position: relative;
  flex: 0 1 100%;
  padding: 1.5rem;
  box-sizing: border-box;
}
@media (min-width: 768px) {
  .cards-grid > * {
    flex: 0 1 50%;
  }
  .cards-grid > *:first-child {
    flex: 0 1 100%;
    border: none;
  }
  .cards-grid > *:first-child::before, .cards-grid > *:first-child::after {
    content: none;
  }
  .cards-grid > *:first-child .card-content {
    display: flex;
    flex-direction: row;
    gap: 3rem;
  }
  .cards-grid > *:first-child .card-image-container {
    width: 60%;
  }
  .cards-grid > *:first-child .card-body {
    width: 40%;
  }
  .cards-grid > *:first-child .card-body.half-grid, .cards-grid > *:first-child .card-image-container.half-grid {
    width: 50%;
  }
  .cards-grid > *:first-child .card-read-more {
    position: relative;
  }
}
@media (min-width: 1024px) {
  .cards-grid > * {
    flex: 0 1 33.333%;
  }
}

.general .card-image-container {
  margin-bottom: 2rem;
}

.card {
  position: relative;
}
.card a:hover {
  text-decoration: none;
  opacity: 0.7;
  transition: all 0.3s ease-in-out;
}

.card-image {
  aspect-ratio: 16/9;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  width: 100%;
}
.card-image.contain {
  aspect-ratio: 1/1;
  object-fit: contain;
}

#home .card-image {
  aspect-ratio: initial;
}

#home .featured-posts .card-image {
  aspect-ratio: 3/2;
}

.card-category-chip {
  font-size: 14px;
  width: fit-content;
  padding: 0.5rem 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  border: 1px solid #202a52;
  border-radius: 9999px;
}

.post-footer-category a:hover {
  text-decoration: none;
  opacity: 0.7;
  transition: all 0.3s ease-in-out;
}

.card-title {
  font-size: 1.6rem;
  font-style: normal;
  line-height: 2.25rem;
  margin-top: 1rem;
}

.card-excerpt {
  margin-top: 1rem;
  margin-bottom: 3.5rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
}

.card-read-more {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.55rem;
  position: absolute;
  bottom: 1.5rem;
  color: #202a52;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.post-back {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.post-back-svg {
  position: absolute;
  top: 50%;
  left: -15px;
  transform: translateY(-50%);
}

.post-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-inline: auto;
  width: 70%;
}

.post-title {
  margin-top: 3rem;
}

.post-top-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 2rem;
}

.post-back-text,
.post-excerpt {
  font-style: normal;
  font-weight: 400;
}

.post-meta {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  gap: 1rem;
}

.post-date {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
}

.post-time {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
}

.post-dot {
  width: 0.5rem;
  margin-inline: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #d9d9d9;
  display: inline-block;
}

.post-image {
  aspect-ratio: 16/9;
  width: 100%;
  object-fit: cover;
  max-height: 25rem;
  margin-top: 3rem;
}

.post-body {
  margin-inline: auto;
  width: 70%;
  margin-top: 3rem;
  margin-bottom: 6rem;
}
@media (max-width: 768px) {
  .post-body {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .post-body {
    width: 85%;
  }
}

.post-summary li,
.post-content li {
  list-style: square;
  margin-left: 1.2rem;
  line-height: 2rem;
}
.post-summary li::marker,
.post-content li::marker {
  color: var(--color-blue);
}
.post-summary p,
.post-content p {
  margin-bottom: 1rem;
}

.post-summary-title {
  font-style: normal;
  font-weight: 400;
  line-height: 2.25rem;
  margin-bottom: 1.5rem;
}

.post-content {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.post-footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 3rem;
}
.post-footer .post-footer-share-title {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6875rem;
}
.post-footer .post-footer-share ul {
  display: flex;
  margin-top: 0.5rem;
}
.post-footer .post-footer-share-links {
  display: flex;
  align-items: flex-end;
}
.post-footer .post-footer-share li,
.post-footer .post-footer-share .link {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  list-style: none;
  margin-left: 0rem;
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
  border: 1px solid #202a52;
  border-radius: 9999px;
  padding: 0.5rem;
}

.related-grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5rem;
  margin-top: 2rem;
  gap: 3rem;
}
.related-grid > * {
  position: relative;
  flex: 0 1 100%;
  box-sizing: border-box;
}
@media (min-width: 768px) {
  .related-grid > * {
    flex: 0 1 46%;
  }
}
@media (min-width: 1024px) {
  .related-grid > * {
    flex: 0 1 29%;
  }
}

.featured-posts {
  padding-bottom: 0rem !important;
}

.post-related-title {
  margin-bottom: 2rem;
  position: relative;
}

.post-related-title::before {
  content: "";
  position: absolute;
  top: -1.35rem;
  left: 0;
  width: 4rem;
  height: 0.34938rem;
  background-color: #e63214;
}

.admin {
  font-family: "Avenir", "Avenir", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
}
.admin .sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 175px;
  background-color: var(--color-blue);
  color: #fff;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  z-index: 80;
}
.admin .sidebar .sidebar-header {
  font-size: 1.5rem;
  padding-left: 20px;
  margin-bottom: 1rem;
}
.admin .sidebar .sidebar-nav {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.admin .sidebar .sidebar-nav .nav-section {
  margin-bottom: 1.5rem;
}
.admin .sidebar .sidebar-nav .nav-section .nav-heading {
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 10px;
  color: #ddd;
  text-transform: uppercase;
}
.admin .sidebar .sidebar-nav .nav-section a {
  text-decoration: none;
  font-size: 0.9rem;
  padding: 10px 10px;
  color: #fff;
  display: block;
  margin-inline: 10px;
}
.admin .sidebar .sidebar-nav .nav-section a.current {
  background-color: #fff;
  color: var(--color-blue);
  border-radius: 5px;
}
.admin .sidebar .logout-btn {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff;
  text-decoration: none;
  font-size: 0.8rem;
}
.admin .main-container {
  margin-left: 175px;
  padding: 20px;
  max-width: calc(100% - 175px);
  padding-bottom: 60px;
  padding-top: 1rem;
}
.admin .top-bar {
  display: flex;
  justify-content: flex-start;
  padding: 10px 20px;
  background-color: var(--color-blue);
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 90;
}
.admin .btn-link {
  text-decoration: none;
  color: var(--color-blue) !important;
}
.admin .table-responsive {
  padding: 20px;
}
.admin .btn-primary {
  background-color: var(--color-blue);
  border-color: var(--color-blue);
  color: #fff !important;
  padding: 10px 20px;
}
.admin label {
  margin: 10px 0;
  display: block;
  position: relative;
}
.admin h1 {
  min-height: auto;
}
.admin form input[type=text],
.admin form input[type=tel],
.admin form input[type=email] {
  line-height: 1.5em;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  height: auto;
}
.admin .page-link,
.admin .active > .page-link,
.admin .page-link.active {
  color: var(--color-blue);
}
.admin .active > .page-link,
.admin .page-link.active {
  background-color: var(--color-blue);
  border-color: var(--color-blue);
  color: white;
}
.admin th > a {
  text-decoration: none;
  color: var(--color-blue);
}
.admin .post-title-table,
.admin .post-title-table:visited {
  color: var(--color-blue);
  text-decoration: none;
}
.admin .post-title-column {
  width: 50%;
  overflow: hidden;
}
.admin .edit-button {
  font-size: 1em;
  width: 100px;
  padding: 5px 10px;
  border-radius: 6px;
}
.admin .edit-button i {
  display: inline;
}
.admin .login {
  margin-top: 4rem;
}
.admin label {
  width: 100% !important;
}
.admin .teammember .tox-editor-header {
  display: none;
}
.admin .teammember .tox-statusbar {
  display: none;
}

/* Additonal Component Stylesheets */
/*
This file is used to contain all component imports.
Files inside this folder can contain specific element styles and layout.
*/
.header-navigation {
  display: flex;
  padding-block: 0.625rem;
  height: 5rem;
  width: 100%;
  position: relative;
}

.header-navigation .logo-container {
  height: 5rem;
  width: 4rem;
}

.header-navigation .logo-container svg {
  width: 100%;
  height: 100%;
  display: block;
}

.header-navigation-list {
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  padding-inline-start: 2rem;
  position: relative;
}

.header-navigation-list > li {
  padding-inline: 1rem;
  position: relative;
  align-self: center;
}

.header-navigation-list a.current {
  font-weight: 900;
}

.header-navigation-list > .navigation-icons {
  padding-inline: 0;
  margin-inline-start: auto;
}

.header-navigation .navigation-icon-list {
  display: flex;
  gap: 0.5rem;
}

.header-navigation .navigation-icon-list svg {
  height: 1.2rem;
  display: inline-block;
}

.header-navigation .navigation-icon-list a {
  border: 0.08rem solid var(--color-blue);
  border-radius: 10rem;
  line-height: 1rem;
  padding: 0.25rem 0.5rem;
  font-weight: 300;
  font-size: 0.75rem;
  display: inline-block;
  vertical-align: middle;
}

.header-navigation .navigation-icon-list a:hover,
.header-navigation .navigation-icon-list a:focus,
.header-navigation .navigation-icon-list a:active {
  text-decoration: none;
  color: var(--color-white);
  background-color: var(--color-blue);
}

.header-navigation .sub-navigation-list {
  display: none;
  position: absolute;
  inset: 100% auto auto 0;
  padding: 1.6rem 1rem 1rem 1rem;
  min-width: 250px;
  background-image: linear-gradient(transparent 0.625rem, #dcdcdc 0.625rem);
  line-height: 2;
  border-top: 31px solid white;
}
.header-navigation .sub-navigation-list a {
  display: block;
}

.header-navigation .sub-navigation-list::after {
  content: "";
  position: absolute;
  background-image: linear-gradient(transparent 0.625rem, #dcdcdc 0.625rem);
  width: 100vw;
  inset: 0 100vw 0 auto;
  display: block;
}

.header-navigation .sub-navigation-list-supplement {
  position: fixed;
  inset: var(--height-header) max(10vw, 50vw - 43.75rem) auto auto;
  padding-block-start: 1.5rem;
  font-size: 0.75rem;
  text-align: end;
  line-height: 1.5;
  display: none;
}

.header-navigation .sub-navigation-list li:not(:last-child),
.header-navigation .sub-navigation-list-supplement li:not(:last-child) {
  padding-block-end: 0.25em;
}

.header-navigation .sub-navigation-list.active,
.header-navigation-list > li:hover > .sub-navigation-list,
.header-navigation .sub-navigation-list:hover,
.header-navigation-list > li:focus-within .sub-navigation-list {
  display: block;
}

.header-navigation .navigation-toggle {
  display: none;
  position: absolute;
  inset: 1.9375rem 0 1.9375rem auto;
  width: 1.375rem;
  height: 1.375rem;
  background-image: linear-gradient(transparent 90%, var(--color-blue) 90%);
  padding-inline-start: 1.375rem;
  overflow: hidden;
}

.header-navigation .navigation-toggle::before,
.header-navigation .navigation-toggle::after {
  content: "";
  background-color: var(--color-blue);
  position: absolute;
  display: block;
  transition: transform 150ms linear;
}

.header-navigation .navigation-toggle::before {
  inset: 0 0 1.25rem 0;
}

.header-navigation .navigation-toggle::after {
  inset: 0.625rem 0 0.625rem 0;
}

.header-navigation.expanded .navigation-toggle::before {
  transform: translateY(0.4678rem) rotate(45deg);
}

.header-navigation.expanded .navigation-toggle::after {
  transform: translateY(-0.1572rem) rotate(-45deg);
}

header {
  height: var(--height-header);
  background-color: var(--color-white);
  color: var(--color-blue);
  position: fixed;
  inset: 0 0 auto 0;
  z-index: 42069;
  border-block-end: 0.1rem solid var(--color-gray);
  font-size: 0.865rem;
}
header a:hover,
header a:focus {
  text-decoration: underline;
  text-underline-offset: 0.5em;
}

header.disclaimer a {
  font-style: normal;
}

header.disclaimer {
  padding-block: 0;
}

footer {
  background-color: var(--color-lightgray);
}
footer a:hover,
footer a:focus {
  text-decoration: underline;
  text-underline-offset: 0.5em;
}
footer .footer-navigation-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 0.8rem;
  padding-block: 2.125rem;
  line-height: 2;
}
footer .footer-navigation-list > li > a {
  font-weight: 700;
}
footer .footer-navigation-list > li:last-child {
  text-align: right;
}
footer .footer-navigation-list .sub-navigation-list:not(:last-child) {
  padding-block-start: 1rem;
}

main > section.strip.contact {
  display: flex;
  justify-content: space-between;
  gap: 8rem;
  flex-wrap: nowrap;
  padding-inline: max(10vw, 50vw - 43.75rem);
}

main > section.strip.contact > form {
  flex-basis: calc(75% - 2rem);
  padding-block-start: 2.1rem;
}

main section.strip .contact h2 {
  padding-block-end: 0rem !important;
}

main section.strip.contact p {
  padding-block-end: 2rem;
}

main > section.strip.visual__version {
  background-image: url("../../assets/images/home/header_background_lady.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  min-height: 40vh;
}
main > section.strip.visual__version h1 {
  margin-bottom: 0.4rem;
}
@media screen and (max-width: 1100px) {
  main > section.strip.visual__version {
    background-size: cover;
  }
}
main > section.strip.visual__version p {
  max-width: 50%;
}
@media screen and (max-width: 680px) {
  main > section.strip.visual__version p {
    max-width: 70%;
  }
}

@media screen and (max-width: 52rem) {
  main > section.strip.visual__version {
    background-image: url("../../assets/images/home/header_background_lady_mobile.jpg");
    background-size: contain;
    background-position: top right;
  }
  main > section.strip.visual__version p {
    max-width: 100%;
  }
  main > section.strip.visual__version h1 {
    margin-top: 16vh;
    font-size: 2.5rem;
    margin-bottom: 0.4rem;
  }
  main > section.strip.contact {
    display: block;
  }
  main > section.strip.contact > form {
    padding-block-start: 2rem;
  }
}
section#private-markets.active .private-markets-mobile .inpage-extra-nav {
  display: none;
  border: 1px solid red;
}

.flex {
  display: flex;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.align-self-center {
  align-self: center;
}

.justify-center {
  justify-content: center;
}

.row-reverse {
  flex-direction: row-reverse !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

.mt-20 {
  margin-top: 1rem;
}

.mt-40 {
  margin-top: 2rem;
}

.w-100 {
  display: flex;
  width: 100%;
}

.text-center {
  text-align: center;
  justify-content: center;
}

.no-padding {
  padding: 0;
}

.cols {
  column-gap: 3rem;
}
.cols.col-2 {
  column-count: 2;
}
@media (max-width: 1024px) {
  .cols.col-2 {
    column-count: 1;
  }
}

.columns {
  display: flex;
  flex-wrap: wrap;
  gap: max(10%, 1rem);
}
.columns .column {
  flex: 1 1 0;
}
.columns .column p:not(:last-child),
.columns .column h2:not(:last-child),
.columns .column h3:not(:last-child),
.columns .column ul:not(:last-child),
.columns .column ol:not(:last-child),
.columns .column figure:not(:last-child) {
  padding-block-end: 2rem;
}
.columns .column ul {
  padding-block-end: 1rem;
}
.columns .column--tight {
  flex: 0;
}
.columns .column.sidebar {
  max-width: 27rem;
}

.strip h2.bar {
  padding-block-end: 2rem;
}

/* Strip is layouting the container for header and footer. Similar to bootstrap container class */
.strip {
  padding-inline: max(10vw, 50vw - 43.75rem);
}

.blue.strip {
  background-color: var(--color-blue);
  color: var(--color-white);
}

.gray.strip {
  background-color: var(--color-gray);
}

h1.strip {
  padding-block: 4rem;
}

section.strip {
  padding-block: 3rem;
}

main {
  margin-block-start: var(--height-header);
  min-height: calc(100vh - 15.625rem - var(--height-header));
}

#pools-open .pools-content,
#pools .pools-content {
  padding-top: 2rem;
}

.inline {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 10px;
}

.mt-2rem {
  margin-top: 2rem;
}

.mt-4rem {
  margin-top: 4rem;
}

.strip .tabs {
  border-bottom: 2px solid var(--color-gray);
  position: relative;
}
.strip .tabs button.daarom-button {
  background: rgba(0, 0, 0, 0);
  position: relative;
  z-index: 1;
  font-weight: 700;
  padding-block: 1rem;
  font-size: 1rem;
  margin-right: 2rem;
  font-family: "Avenir", sans-serif !important;
}
.strip .tabs button.daarom-button:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 2px;
  background: transparent;
  z-index: -1;
}
.strip .tabs button.daarom-button.active:after {
  background: var(--WMP---Highlight, #e63214);
}
@media (max-width: 1024px) {
  .strip .tabs button.daarom-button {
    margin-right: 1rem;
  }
}
@media (max-width: 768px) {
  .strip .tabs button.daarom-button {
    margin-right: 0.25rem;
  }
}
@media (max-width: 480px) {
  .strip .tabs button.daarom-button {
    font-size: 0.75rem;
  }
}
.strip .waarom-content {
  display: none;
}
.strip .waarom-content.active {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4rem;
  margin-top: 2rem;
}
.strip .waarom-content.active div {
  width: 50%;
}
.strip .waarom-content.active img {
  width: 50%;
}
@media (max-width: 1024px) {
  .strip .waarom-content.active {
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;
  }
  .strip .waarom-content.active div {
    width: 100%;
  }
  .strip .waarom-content.active img {
    width: 100%;
  }
}

@media screen and (max-width: 53rem) {
  .mobile-hide {
    display: none;
  }
}
@media screen and (min-width: 53rem) {
  .desktop-hide {
    display: none;
  }
}
.header {
  margin-bottom: 4rem;
}
.header .header-content {
  padding: 4rem 0 0 0;
  margin-bottom: 4rem;
}
.header .header-content .text {
  width: 70%;
}
.header .header-content .text a {
  text-decoration: underline;
}
.header .header-content .text * {
  max-width: 500px;
}
@media screen and (min-width: 1400px) {
  .header .header-content .text * {
    max-width: 640px;
  }
}
@media screen and (min-width: 1700px) {
  .header .header-content .text * {
    max-width: 700px;
  }
}
.header h1 {
  min-height: 0rem;
  margin-bottom: 1rem;
  line-height: 1.3;
}
.header p {
  margin-bottom: 1rem;
}
.header p:last-of-type {
  padding-bottom: 0;
}
.header .image {
  position: relative;
  right: 4%;
  top: 0;
  width: 100%;
  max-width: 370px;
}
.header .abs-image {
  position: absolute;
  top: 20px;
}
@media screen and (max-width: 45rem) {
  .header .header-content .image {
    margin-bottom: -80px;
    margin-top: 0px;
    right: auto;
  }
  .header .header-content .abs-image {
    position: relative;
  }
}
@media screen and (max-width: 45rem) {
  .header .header-content .image,
  .header .header-content .text {
    width: 100%;
  }
  .header .header-content {
    flex-direction: column;
  }
}

#home .visual__version {
  min-height: auto;
}
#home .header {
  margin-bottom: 0rem;
}
#home .header .header-content {
  margin-bottom: 0;
  padding-top: 0;
}

#about .header .image {
  width: 1220px;
}
@media screen and (min-width: 600px) {
  #about .header .image {
    top: 80px;
  }
}
@media screen and (max-width: 45rem) {
  #about .header .image {
    width: 100%;
  }
}

#assetmanagement .header-content:lang(en-GB),
#about .header-content:lang(en-GB) {
  padding: 4rem 0;
}

#vermogensbeheer .header-content,
#about .header-content {
  padding: 4rem 0 3rem 0;
}

#home .subheading {
  display: block;
  margin-bottom: 1rem;
  font-size: 2.5rem;
}
#home .subheading:before {
  margin-bottom: 0.5rem;
}

/*
breakbpoint for smaller devices (720px) and to avoid scaling indefinitly on larger screens (1500px)
KBIA:ideally this goes per component. for now leave it as is.
*/
@media screen and (max-width: 53rem) {
  .header-navigation .navigation-toggle {
    display: initial;
  }
  .header-navigation-list {
    position: absolute;
    inset: var(--height-header) min(-10vw, 43.75rem - 50vw) auto min(-10vw, 43.75rem - 50vw);
    padding-inline: max(10vw, 50vw - 43.75rem);
    transform-origin: 50% 0;
    transform: scaleY(0);
    background-color: var(--color-gray);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    line-height: 3rem;
    max-height: calc(100vh - var(--height-header));
    padding-block: 1rem;
  }
  .header-navigation-list::before {
    content: "";
    display: block;
    background-color: var(--color-red);
    height: 0.35rem;
    width: 4rem;
    box-sizing: content-box;
  }
  .header-navigation.expanded .header-navigation-list {
    transform: scaleY(1);
    transition: transform 150ms linear;
  }
  .header-navigation-list > li {
    padding-inline: 0;
    font-weight: 700;
    align-self: auto;
  }
  .header-navigation-list .sub-navigation-list {
    display: block;
    width: initial;
    position: static;
    font-weight: 400;
    padding: 0;
    border-top: none;
  }
  .header-navigation-list .sub-navigation-list > li {
    line-height: normal;
    align-self: center;
  }
  .header-navigation-list > .navigation-icons {
    inset: 1rem max(10vw, 50vw - 43.75rem) auto auto;
    line-height: normal;
  }
  .header-navigation-list .sub-navigation-list-supplement {
    display: none;
  }
  .header-navigation-list > li:first-child .sub-navigation-list-supplement {
    display: block;
    position: absolute;
    inset: 2rem 0 auto auto;
    padding-block-start: 0;
  }
  .header-navigation-list .navigation-icons {
    display: none;
  }
  .footer-navigation-list {
    flex-wrap: wrap;
  }
  .footer-navigation-list > li:last-child {
    text-align: left;
  }
  .footer-navigation-list > li {
    flex-basis: 50%;
    padding-block-end: 1rem;
  }
}
@media screen and (max-width: 300px) {
  .footer-navigation-list > li {
    flex-basis: 100%;
  }
}
@media screen and (min-width: 53rem) {
  .header-navigation .navigation-toggle {
    display: none;
  }
}
@media screen and (max-width: 53rem) {
  .mobile-hide {
    display: none;
  }
}
@media screen and (min-width: 53rem) {
  .desktop-hide {
    display: none;
  }
}