footer {
    background-color: var(--color-lightgray);

    a:hover,
    a:focus {
        text-decoration: underline;
        text-underline-offset: 0.5em;
    }

    .footer-navigation-list {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        font-size: 0.8rem;
        padding-block: calc(1.5rem + 0.625rem);
        line-height: 2;
    }
    .footer-navigation-list > li > a {
        font-weight: 700;
    }
    .footer-navigation-list > li:last-child {
        text-align: right;
    }
    .footer-navigation-list .sub-navigation-list:not(:last-child) {
        padding-block-start: 1rem;
    }
}
