img {
    display: block;
    max-width: 100%;
}
figcaption + img {
    padding-block-start: 1rem;
}
figcaption {
    strong {
        font-weight: 700;
        display: block;
    }
    + img {
        padding-block-start: 1rem;
    }
}
