.contact-pool-alert {
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    margin-top: 4rem;
    padding-inline: max(10vw, 50vw - 43.75rem);
    grid-area: poolalert;

    .contact-pool-alert-content {
        position: relative;
        padding-inline: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;

        .contact-pool-alert-content-card {
            height: 100%;
            background-color: white;

            .contact-pool-alert-title {
                margin-top: 4rem;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: -1rem;
                    left: 0;
                    width: 4rem;
                    height: 0.34938rem;
                    background-color: #e63214;
                }
            }

            .contact-pool-alert-text {
                margin-block: 2rem;
            }

            .contact-pool-alert-privacy {
                margin-top: 2rem;
                font-size: 14px;

                a {
                    text-decoration: underline;
                }
            }
        }
    }

    img.contact-pool-alert-image-desktop {
        padding-inline: 0;
        display: none;
        width: 100%;
        object-fit: cover;
        aspect-ratio: 1/1;
    }

    img.contact-pool-alert-image-moblile {
        display: block;
        object-fit: cover;
    }

    @media screen and (min-width: 45rem) {
        margin: 0 auto;
        padding-inline: 0;
        width: 80%;
        flex-direction: unset;
        height: 44rem;

        img.contact-pool-alert-image-desktop {
            display: block;
        }

        img.contact-pool-alert-image-moblile {
            display: none;
        }

        .contact-pool-alert-content {
            position: absolute;
        }

        .contact-pool-alert-content-card {
            margin-inline: 5rem;
            padding-inline: 0;
            min-height: 70%;
            height: unset !important;
            width: 75%;
            max-height: 85%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .contact-pool-alert-content-card-body {
            margin-inline: 3rem;
        }
    }

    @media screen and (min-width: 1024px) {
        .contact-pool-alert-content-card {
            width: 65%;
        }
    }

    @media screen and (min-width: 1280px) {
        width: 100%;

        img.contact-pool-alert-image-desktop {
            padding-inline: max(10vw, 50vw - 43.75rem);
        }

        .contact-pool-alert-content {
            padding-inline: max(10vw, 50vw - 43.75rem);
        }

        .contact-pool-alert-content-card {
            width: 37%;
        }
    }

    .contact-pool-alert-button {
        margin-top: 2rem;
        margin-bottom: 3rem;
    }
}
