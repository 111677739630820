/*
This file is used to contain all base imports.
Files inside this folder can contain global styles used in the project.
*/

//Import Base files
@import 'reset';
@import 'typography';
@import 'breakpoints';

/* Additonal Base Stylesheets */
:root {
    --color-blue: rgb(32, 42, 82);
    --color-lightgray: rgb(238, 238, 238);
    --color-red: rgb(230, 50, 20);
    --color-browngray: rgb(90, 80, 70);
    --color-darkgray: rgb(100, 100, 100);
    --color-gray: rgb(220, 220, 220);
    --color-white: #ffffff;

    --height-header: 6.25rem;

    font-size: 16px;
    font-family: $main-font;
}

* {
    padding: 0;
    margin: 0;
}

html {
    scroll-padding-block-start: var(--height-header);
    overflow-x: hidden;
}
body {
    line-height: 1.5;
    color: var(--color-blue);
    font-family: $main-font;
}


.mb-0{
    margin-bottom: 0 !important;
    padding-block-end: 0 !important;
}