.post-back {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.post-back-svg {
    position: absolute;
    top: 50%;
    left: -15px;
    transform: translateY(-50%);
}
.post-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-inline: auto;
    width: 70%;
}
.post-title {
    margin-top: 3rem;
}
.post-top-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 2rem;
}
.post-back-text,
.post-excerpt {
    font-style: normal;
    font-weight: 400;
}

.post-meta {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    gap: 1rem;
}

.post-date {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
}

.post-time {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
}

.post-dot {
    width: 0.5rem;
    margin-inline: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #d9d9d9;
    display: inline-block;
}

.post-image {
    aspect-ratio: 16/9;
    width: 100%;
    object-fit: cover;
    max-height: 25rem;
    margin-top: 3rem;
}

.post-body {
    margin-inline: auto;
    width: 70%;
    margin-top: 3rem;
    margin-bottom: 6rem;

    @media (max-width: 768px) {
        width: 100%;
    }

    @media (max-width: 1024px) {
        width: 85%;
    }
}

.post-summary,
.post-content {
    & li {
        list-style: square;
        margin-left: 1.2rem;
        line-height: 2rem;
    }
    & li::marker {
        color: var(--color-blue);;
    }
    & p {
        margin-bottom: 1rem;
    }
}

.post-summary-title {
    font-style: normal;
    font-weight: 400;
    line-height: 2.25rem;
    margin-bottom: 1.5rem;
}

.post-content {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.post-footer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 3rem;

    .post-footer-share-title {
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.6875rem;
    }

    .post-footer-share ul {
        display: flex;
        margin-top: 0.5rem;
    }
    .post-footer-share-links {
        display: flex;
        align-items: flex-end;
    }
    .post-footer-share li,
    .post-footer-share .link {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        list-style: none;
        margin-left: 0rem;
        height: 1rem;
        width: 1rem;
        margin-right: 0.5rem;
        border: 1px solid #202a52;
        border-radius: 9999px;
        padding: 0.5rem;
    }
}
