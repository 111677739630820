.blog-header {
    margin-top: 5.25rem;
    height: 16.5rem;
    padding: 1.5rem;
    color: #fff;
    background-color: #202a52;
    display: flex;
    align-items: center;
}

.blog-header-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    position: relative;
    padding: 1.5rem;
    margin-bottom: 4.5rem;

    @media (min-width: 640px) {
        max-width: 480px;
    }

    @media (min-width: 768px) {
        max-width: 580px;
    }

    @media (min-width: 1024px) {
        max-width: 780px;
    }

    @media (min-width: 1280px) {
        max-width: 980px;
    }

    @media (min-width: 1536px) {
        max-width: 1180px;
    }
}

.blog-header-title {
    position: relative;
    min-height: unset;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
        font-size: 2.5rem;
        line-height: 3.75rem;
    }

    @media (max-width: 576px) {
        font-size: 2rem;
        line-height: 3rem;
    }

    @media (max-width: 320px) {
        font-size: 1.5rem;
        line-height: 2.25rem;
    }
}

.blog-header-title::before {
    content: '';
    position: absolute;
    top: -1.3rem;
    left: 0;
    width: 4rem;
    height: 0.34938rem;
    background-color: #e63214;
}

.blog-header-subtitle {
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.85rem;

    @media (max-width: 768px) {
        font-size: 1rem;
        line-height: 1.5rem;
    }

    @media (max-width: 576px) {
        font-size: 0.9rem;
        line-height: 1.35rem;
    }

    @media (max-width: 320px) {
        font-size: 0.8rem;
        line-height: 1.2rem;
    }
}
