.blog-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0rem;
    position: relative;
    padding: 1.5rem;
    margin-bottom: 4.5rem;

    @media (min-width: 640px) {
        max-width: 480px;
    }

    @media (min-width: 768px) {
        max-width: 580px;
    }

    @media (min-width: 1024px) {
        max-width: 780px;
    }

    @media (min-width: 1280px) {
        max-width: 980px;
    }

    @media (min-width: 1536px) {
        max-width: 1180px;
    }
}

.contact-container {
    display: flex;
    flex-wrap: nowrap !important;
    gap: 6rem;

    .contact {
        width: 50%;
    }

    .contact-image {
        width: 50%;

        img {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            object-fit: cover;
            aspect-ratio: 1/1;
        }
    }

    .contact-text {
        margin-top: 2rem;
    }

    .contact-text p {
        margin-bottom: 2rem;
    }

    @media (max-width: 1024px) {
        flex-direction: column;
        gap: 0;

        .contact {
            width: 100%;
        }

        .contact-image {
            margin-top: 2rem;
            width: 100%;

            img {
                width: 100%;
            }
        }
    }
}
