q {
    &:before {
        content: open-quote;
    }

    &:after {
        content: open-quote;
    }
}

.quotee {
    
    display: block;
    padding-block-start: 0.5rem;
    font-size: 75%;

    @media screen and (min-width: 79rem) {
    font-size: 0.375em;
    }
    
    &.inline {
        display: inline;
    }
}
