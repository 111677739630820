.card {
    position: relative;

    a:hover {
        text-decoration: none;
        opacity: 0.7;
        transition: all 0.3s ease-in-out;
    }
}

.card-image {
    aspect-ratio: 16/9;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;
    width: 100%;

    &.contain {
        aspect-ratio: 1/1;
        object-fit: contain;
    }
}
#home .card-image {
    aspect-ratio: initial;
}

#home .featured-posts .card-image {
    aspect-ratio: 3/2;
}
.card-category-chip {
    font-size: 14px;
    width: fit-content;
    padding: 0.5rem 1rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    border: 1px solid #202a52;
    border-radius: 9999px;
}

.post-footer-category {
    a:hover {
        text-decoration: none;
        opacity: 0.7;
        transition: all 0.3s ease-in-out;
    }
}
.card-title {
    font-size: 1.6rem;
    font-style: normal;
    line-height: 2.25rem;
    margin-top: 1rem;
}

.card-excerpt {
    margin-top: 1rem;
    margin-bottom: 3.5rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
}

.card-read-more {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.55rem;
    position: absolute;
    bottom: 1.5rem;
    color: #202a52;
    display: flex;
    align-items: center;
    gap: 0.3rem;
}
