/*
This file is used to contain all component imports.
Files inside this folder can contain specific element styles and layout.
*/

//Import Layout files
@import 'header-navigation';
@import 'header';
@import 'footer';
@import 'strip';
@import 'private-markets';
//Additonal Layouts Stylesheets
.flex {
    display:flex;
}
.row {
    flex-direction: row;
}
.column {
    flex-direction: column;
}
.items-center {
    align-items: center;
}
.align-self-center {
    align-self: center;
}
.justify-center {
    justify-content: center;
}
.row-reverse {
    flex-direction: row-reverse !important;
}
.flex-grow-1 {
    flex-grow:1;
}
.mt-20 {
    margin-top:1rem
}
.mt-40 {
    margin-top:2rem
}
.w-100 {
    display: flex;
    width:100%;
}
.text-center {
    text-align:center;
    justify-content: center;
}
.no-padding {
    padding: 0;
}
.cols {
    column-gap: 3rem;
    
    &.col-2 {
        column-count: 2;
    }

    @media (max-width: 1024px) {
        &.col-2 {
            column-count: 1;
        }
    }
}


.columns {
    display: flex;
    flex-wrap: wrap;
    gap: max(10%, 1rem);

    .column {
        flex: 1 1 0;

        p:not(:last-child),
        h2:not(:last-child),
        h3:not(:last-child),
        ul:not(:last-child),
        ol:not(:last-child),
        figure:not(:last-child) {
            padding-block-end: 2rem;
        }
        ul {
            padding-block-end: 1rem;
        }
        &--tight {
            flex: 0;
        }

        &.sidebar {
            max-width: 27rem;
        }
    }
}
.strip h2.bar {
    padding-block-end: 2rem;
}
/* Strip is layouting the container for header and footer. Similar to bootstrap container class */
.strip {
    padding-inline: max(10vw, 50vw - 43.75rem);
}

.blue.strip {
    background-color: var(--color-blue);
    color: var(--color-white);
}
.gray.strip {
    background-color: var(--color-gray);
}
h1.strip{
    padding-block: 4rem;
}
section.strip {
    padding-block: 3rem;
}
main {
    margin-block-start: var(--height-header);
    min-height: calc(100vh - 15.625rem - var(--height-header));
}

#pools-open .pools-content,
#pools .pools-content {
    padding-top: 2rem;
}

.inline {
    display: inline-block;
    vertical-align: middle;
    width: 15px;
    height: 10px;
}

.mt-2rem {
    margin-top: 2rem;
}

.mt-4rem {
    margin-top: 4rem;
}

.strip {
  
   
    .tabs {
        border-bottom: 2px solid var(--color-gray);
        position: relative;

        button.daarom-button {
            background: rgba(0, 0, 0, 0);
            position: relative;
            z-index: 1;
            font-weight: 700;
            padding-block: 1rem;
            font-size: 1rem;
            margin-right: 2rem;
            font-family: 'Avenir', sans-serif !important;

            &:after {
                content: '';
                position: absolute;
                bottom: -2px;
                left: 0;
                right: 0;
                height: 2px;
                background: transparent;
                z-index: -1;
            }

            &.active:after {
                background: var(--WMP---Highlight, #e63214);
            }

            @media (max-width: 1024px) {
                margin-right: 1rem;
            }

            @media (max-width: 768px) {
                margin-right: 0.25rem;
            }

            @media (max-width: 480px) {
                font-size: 0.75rem;
            }
        }
    }

    .waarom-content {
        display: none;
    }

    .waarom-content.active {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4rem;
        margin-top: 2rem;

        div {
            width: 50%;
        }

        img {
            width: 50%;
        }

        @media (max-width: 1024px) {
            flex-direction: column;
            gap: 2rem;
            margin-top: 2rem;

            div {
                width: 100%;
            }

            img {
                width: 100%;
            }
        }
    }
}
@media screen and (max-width: 53rem) {
    .mobile-hide {
        display:none;
    }
}

@media screen and (min-width: 53rem) {
    .desktop-hide {
        display:none;
    }
}


.header {
    margin-bottom: 4rem;
    .header-content {
        padding: 4rem 0 0 0;
     
        .text {
            width:70%;
        }
        .text a { text-decoration: underline;}
   
      
        .text * {
            max-width: 500px;
        }
   
        @media screen and (min-width: 1400px) {
            .text * {
                max-width: 640px;
            }
        }
        @media screen and (min-width: 1700px) {
            .text * {
                max-width: 700px;
            }
        }
        margin-bottom: 4rem;

    }
    h1 {
        min-height: 0rem;
        margin-bottom: 1rem;
        line-height: 1.3;
    }

    p {
        margin-bottom: 1rem;
    }
    p:last-of-type {
        padding-bottom: 0;
    }
    .image {
        position: relative;
        right: 4%;
        top: 0;
        width: 100%;
        max-width: 370px;

       
    }
    .abs-image {
        position: absolute;
        top: 20px;
    }


    // @media screen and (max-width: 700) {
    //     .header-content .image {
    //         height: calc(71vw - max(10vw, 50vw - 43.75rem))
    //     }
    // }
    // @media screen and (max-width: 600px) {
    //     .header-content .image {
    //         height: calc(71vw - max(10vw, 50vw - 43.75rem))
    //     }
    // }
    // @media screen and (max-width: 500px) {
    //     .header-content .image {
    //         height: calc(61vw - max(10vw, 50vw - 43.75rem))
    //     }
    // }

    @media screen and (max-width: 45rem) {
        .header-content .image {
            margin-bottom: -80px;
            margin-top: 0px;
            right:auto;
        }
        .header-content .abs-image {
            position: relative;
            // height: calc(54.8vw - max(10vw, 50vw - 43.75rem))
        }
    }
   
    @media screen and (max-width: 45rem) {
        .header-content .image,
        .header-content .text{
            width: 100%;

        }

        

        .header-content {
            flex-direction: column;
        }

       

       
    }
    
}

#home {
    .visual__version {
        min-height: auto;
    }
    .header {
        margin-bottom: 0rem;
    
        .header-content {
            margin-bottom:0;
            padding-top:0; 
    
           
        }
    }
}

#about .header {
    .image {
        width: 1220px;
        @media screen and (min-width: 600px) {
            top:80px;
        }
        @media screen and (max-width: 45rem) {
            width: 100%;
        }
    }

   
}
#assetmanagement .header-content:lang(en-GB),
#about .header-content:lang(en-GB) {
    padding: 4rem 0;
}

#vermogensbeheer .header-content ,
#about .header-content {
    padding: 4rem 0 3rem 0;
}

#home .subheading {
    display: block;
    margin-bottom: 1rem;
    
    font-size: 2.5rem;
    &:before {
        margin-bottom: .5rem;
    }
}


//.header .image